import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { Reservation, ReservationList } from '../shared/reservation.model';

@Component({
  selector: 'app-kalender',
  templateUrl: './kalender.component.html',
  styleUrls: ['./kalender.component.scss']
})
export class KalenderComponent {
  // Calendar: https://stackoverflow.com/questions/59943990/is-there-any-way-i-can-make-custom-datepicker-without-using-angular-material-or/59945162#59945162
  public year: number = new Date().getFullYear();
  public month: number = (new Date().getMonth() + 1);
  public daysZilt: any[];
  public daysSand: any[];
  public aankomstDatumsZilt: Array<Date> = [];
  public vertrekDatumsZilt: Array<Date> = [];
  public aankomstDatumsSand: Array<Date> = [];
  public vertrekDatumsSand: Array<Date> = [];
  public overigeGeboekteDatumsZilt: Array<Date> = [];
  public overigeGeboekteDatumsSand: Array<Date> = [];
  public maximaleBekijkDatum: Date = new Date(new Date().getFullYear() + 1, 11, 31);
  public months = [];
  public weekdays = [];
  public alleenZiltTonen: boolean;

  constructor(private _lodgeBackendService: LodgeBackendService, private translateService: TranslateService) {
    this.alleenZiltTonen = environment.alleenZiltTonen;
    this.SetTranslateMonths();
    this.SetTranslateDays();
    this.GetReserveringen();
  }

  private SetTranslateDays(): void {
    this.translateService.get('kalender-19').subscribe(value => {
      this.weekdays.push(value);
    });
    this.translateService.get('kalender-20').subscribe(value => {
      this.weekdays.push(value);
    });
    this.translateService.get('kalender-21').subscribe(value => {
      this.weekdays.push(value);
    });
    this.translateService.get('kalender-22').subscribe(value => {
      this.weekdays.push(value);
    });
    this.translateService.get('kalender-23').subscribe(value => {
      this.weekdays.push(value);
    });
    this.translateService.get('kalender-24').subscribe(value => {
      this.weekdays.push(value);
    });
    this.translateService.get('kalender-25').subscribe(value => {
      this.weekdays.push(value);
    });
  }

  private SetTranslateMonths(): void {
    this.translateService.get('kalender-7').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-8').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-9').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-10').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-11').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-12').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-13').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-14').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-15').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-16').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-17').subscribe(value => {
      this.months.push(value);
    });
    this.translateService.get('kalender-18').subscribe(value => {
      this.months.push(value);
    });
  }

  private GetReserveringen() {
    // Reserveringen Zilt ophalen
    this._lodgeBackendService.getReservations('Zilt').toPromise().then(r => {
      var datums = new ReservationList().deserialize(r);
      this.aankomstDatumsZilt = datums.Reservations.map(d => d.aankomstdatum);
      this.vertrekDatumsZilt = datums.Reservations.map(d => d.vertrekdatum);
      datums.Reservations.forEach(reservation => this.GetDatumsTussenVertrekEnAankomstZilt(reservation));
      // Eerst leegmaken, dan dagen bepalen
      this.daysZilt = [];
      this.daysZilt = this.generateDaysZilt(this.year, this.month);
     });
     // Reserveringen Sand ophalen
     if(!this.alleenZiltTonen) {
       this._lodgeBackendService.getReservations('Sand').toPromise().then(r => {
        var datums = new ReservationList().deserialize(r);
        this.aankomstDatumsSand = datums.Reservations.map(d => d.aankomstdatum);
        this.vertrekDatumsSand = datums.Reservations.map(d => d.vertrekdatum);
        datums.Reservations.forEach(reservation => this.GetDatumsTussenVertrekEnAankomstSand(reservation));
        // Eerst leegmaken, dan dagen bepalen
        this.daysSand = [];
        this.daysSand = this.generateDaysSand(this.year, this.month);
       });
     }
  }

  private GetDatumsTussenVertrekEnAankomstZilt(reservation: Reservation): void  {
    this.getDatesZilt(reservation.aankomstdatum, reservation.vertrekdatum);
  }

  private GetDatumsTussenVertrekEnAankomstSand(reservation: Reservation): void  {
    this.getDatesSand(reservation.aankomstdatum, reservation.vertrekdatum);
  }

  private getDatesZilt(startDateOld, endDateOld): void {
    // Omparsen naar juiste date format
    let startDate = new Date(startDateOld);
    let endDate = new Date(endDateOld);
    // Day toevoegen aan while loop
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    let currentDate = addDays.call(startDate, 1);
    while (currentDate < endDate) {
      // Date toevoegen aan array overigeGeboekteDatums
      this.overigeGeboekteDatumsZilt.push(currentDate);
      // Opnieuw day toevoegen
      currentDate = addDays.call(currentDate, 1)
    }
  }

  private getDatesSand(startDateOld, endDateOld): void {
    // Omparsen naar juiste date format
    let startDate = new Date(startDateOld);
    let endDate = new Date(endDateOld);
    // Day toevoegen aan while loop
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    let currentDate = addDays.call(startDate, 1);
    while (currentDate < endDate) {
      // Date toevoegen aan array overigeGeboekteDatums
      this.overigeGeboekteDatumsSand.push(currentDate);
      // Opnieuw day toevoegen
      currentDate = addDays.call(currentDate, 1)
    }
  }

  public incrementMonth(increment): void {
    // Nieuwe maand uitrekenen adhv de increment
    const month = this.month + increment === 13 ? 1 : this.month + increment === 0 ? 12 : this.month + increment;
    // Nieuw jaar uitrekenen adhv de increment
    const year = this.month + increment === 13 ? this.year + 1 : this.month + increment === 0 ? this.year - 1 : this.year;

    // Niet verder terugkijken dan datum van vandaag of verder dan 31 dec volgend jaar
    if(!(this.month === new Date().getMonth() + 1 && this.year === new Date().getFullYear() && increment === -1) &&
       month <= (this.maximaleBekijkDatum.getMonth() + 1) && year <= (this.maximaleBekijkDatum.getFullYear()))
    {
      // Nieuw jaar zetten
      this.year = this.month + increment === 13 ? this.year + 1 : this.month + increment === 0 ? this.year - 1 : this.year;
      // Nieuwe maand zetten
      this.month = month;
      // Nieuwe dagen genereren Zilt
      this.daysZilt = this.generateDaysZilt(this.year, this.month);
      // Nieuwe dagen genereren Sand
      this.daysSand = this.generateDaysSand(this.year, this.month);
    }
  }

  private generateDaysZilt(year: number, month: number): any[] {
    const increment = this.getIncrement(year, month);
    const days = [];
    [0, 1, 2, 3, 4, 5].forEach((x, index) => {
      days.push([]);
      for (let y = 0; y < 7; y++) {
        const date = this.getDateZilt(x, y, year, month, increment);
        days[index].push(date);
      }
    });
    return days;
  }

  private generateDaysSand(year: number, month: number): any[] {
    const increment = this.getIncrement(year, month);
    const days = [];
    [0, 1, 2, 3, 4, 5].forEach((x, index) => {
      days.push([]);
      for (let y = 0; y < 7; y++) {
        const date = this.getDateSand(x, y, year, month, increment);
        days[index].push(date);
      }
    });
    return days;
  }

  private getIncrement(year: number, month: number): number {
    let date = new Date(year, month -1, 1);
    let increment = date.getDay() > 0 ? date.getDay() - 2 : 5;
    return increment;
  }

  private getDateZilt(week: number, dayWeek: number, year: number, month: number, increment: number): any {
    let date: any;
    let day = week * 7 + dayWeek - increment;
    // Als day kleiner is dan 0, laatste dagen van vorige maand
    if (day <= 0) {
      let fechaAuxiliar = new Date(year, month -1, 1);
      // Nieuwe datums maken
      date = new Date(
        fechaAuxiliar.getTime() + (day - 1) * 24 * 60 * 60 * 1000
      );
    } else {
      // Normaal gesproken zal hier een valide datum uit komen
      date = new Date(year, month -1, day);
      // De eerste dagen van de volgende maand zullen in de IF komen
      if (isNaN(date.getTime())) {
        let fechaAuxiliar = new Date(year, month -1, 1);
        // Parse de eerste dagen van de volgende maand naar juiste datum formats
        // Bug: november gaat nog niet goed. Hack gemaakt om november goed te laten gaan.
        if (month === 10) {
          date = new Date(
            fechaAuxiliar.getTime() + (day) * 24 * 60 * 60 * 1000
          );
        }
        else{
          date = new Date(
            fechaAuxiliar.getTime() + (day - 1) * 24 * 60 * 60 * 1000
          );
        }
      }
    }
    
    // Resultaat van het parsen teruggeven
    return {
      dateTxt: date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2),
      date: date,
      day: date.getDate(),
      isMonth: date.getMonth() === month - 1,
      isAankomstDag: this.aankomstDatumsZilt.some(d => new Date(d).toDateString() === new Date(date).toDateString()),
      isVertrekDag: this.vertrekDatumsZilt.some(d => new Date(d).toDateString() === new Date(date).toDateString()),
      isGeboekteDag: this.overigeGeboekteDatumsZilt.some(d => new Date(d).toDateString() === new Date(date).toDateString())
    };
  }

  private getDateSand(week: number, dayWeek: number, year: number, month: number, increment: number): any {
    let date: any;
    let day = week * 7 + dayWeek - increment;
    // Als day kleiner is dan 0, laatste dagen van vorige maand
    if (day <= 0) {
      let fechaAuxiliar = new Date(year, month -1, 1);
      // Nieuwe datums maken
      date = new Date(
        fechaAuxiliar.getTime() + (day - 1) * 24 * 60 * 60 * 1000
      );
    } else {
      // Normaal gesproken zal hier een valide datum uit komen
      date = new Date(year, month -1, day);
      // De eerste dagen van de volgende maand zullen in de IF komen
      if (isNaN(date.getTime())) {
        let fechaAuxiliar = new Date(year, month -1, 1);
        // Parse de eerste dagen van de volgende maand naar juiste datum formats
        // Bug: november gaat nog niet goed. Hack gemaakt om november goed te laten gaan.
        if (month === 10) {
          date = new Date(
            fechaAuxiliar.getTime() + (day) * 24 * 60 * 60 * 1000
          );
        }
        else{
          date = new Date(
            fechaAuxiliar.getTime() + (day - 1) * 24 * 60 * 60 * 1000
          );
        }
      }
    }
    // Resultaat van het parsen teruggeven
    return {
      dateTxt: date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2),
      date: date,
      day: date.getDate(),
      isMonth: date.getMonth() === month - 1,
      isAankomstDag: this.aankomstDatumsSand.some(d => new Date(d).toDateString() === new Date(date).toDateString()),
      isVertrekDag: this.vertrekDatumsSand.some(d => new Date(d).toDateString() === new Date(date).toDateString()),
      isGeboekteDag: this.overigeGeboekteDatumsSand.some(d => new Date(d).toDateString() === new Date(date).toDateString())
    };
  }
}
