<div class="container extraspacing">
    <div class="row ">
        <div class="col-md-12">
            <h2>
                {{ 'prijzen-1' | translate }}
            </h2>
            <p>
                {{ 'prijzen-2' | translate }}
            </p>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <app-kalender></app-kalender>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row ">
        <div class="col-md-12">
            <h4>
                {{ 'prijzen-3' | translate }}
            </h4>
            <p>
                {{ 'prijzen-4' | translate }}
            </p>
        </div>
    </div>

    <div class="row extraspacing">
        <div class="col-md-12">
            <form class="form" [formGroup]="reserveringsForm" autocomplete="off">
                <h3>
                    {{ 'prijzen-5' | translate }}
                </h3>
                <p></p>
                <!-- Row Voornaam - Achternaam -->
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-6' | translate }} *</mat-label>
                            <input type="naam" matInput formControlName="voornaamControl"
                                placeholder="Vul hier je voornaam in">
                            <mat-error *ngIf="reserveringsForm.get('voornaamControl').hasError('required')">
                                {{ 'prijzen-7' | translate }}
                                <strong>{{ 'prijzen-8' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('voornaamControl').hasError('minlength')">
                                {{ 'prijzen-9' | translate }}
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('voornaamControl').hasError('maxlength')">
                                {{ 'prijzen-10' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-11' | translate}} *</mat-label>
                            <input type="naam" matInput formControlName="achternaamControl"
                                placeholder="Vul hier je achternaam in">
                            <mat-error *ngIf="reserveringsForm.get('achternaamControl').hasError('required')">
                                {{ 'prijzen-12' | translate}}
                                <strong>{{ 'prijzen-8' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('achternaamControl').hasError('minlength')">
                                {{ 'prijzen-13' | translate}}
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('achternaamControl').hasError('maxlength')">
                                {{ 'prijzen-14' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Row Email - Telefoonnummer -->
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-15' | translate}} *</mat-label>
                            <input type="naam" matInput formControlName="emailControl"
                                placeholder="Vul hier je emailadres in">
                            <mat-error *ngIf="reserveringsForm.get('emailControl').hasError('required')">
                                {{ 'prijzen-16' | translate}}
                                <strong>{{ 'prijzen-8' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('emailControl').hasError('email')">
                                {{ 'prijzen-17' | translate}}
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('emailControl').hasError('maxlength')">
                                {{ 'prijzen-18' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-19' | translate}} *</mat-label>
                            <input type="naam" matInput formControlName="telefoonNrControl"
                                placeholder="Vul hier je telefoonnummer in">
                            <mat-error *ngIf="reserveringsForm.get('telefoonNrControl').hasError('required')">
                                {{ 'prijzen-20' | translate}}
                                <strong>{{ 'prijzen-8' | translate}}</strong>
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('telefoonNrControl').hasError('minlength')">
                                {{ 'prijzen-21' | translate}}
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('telefoonNrControl').hasError('maxlength')">
                                {{ 'prijzen-22' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Row Aankomstdatum - Vertrekdatum -->
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="fullwidth">
                            <mat-label>{{ 'prijzen-23' | translate}} *</mat-label>
                            <input matInput [matDatepicker]="aankomstdatum" formControlName="aankomstDatumControl">
                            <mat-datepicker-toggle matSuffix [for]="aankomstdatum"></mat-datepicker-toggle>
                            <mat-datepicker #aankomstdatum></mat-datepicker>
                            <mat-error *ngIf="reserveringsForm.get('aankomstDatumControl').hasError('required')">
                                {{ 'prijzen-24' | translate}}
                                <strong>{{ 'prijzen-8' | translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="fullwidth">
                            <mat-label>{{ 'prijzen-25' | translate}} *</mat-label>
                            <input matInput [matDatepicker]="vertrekdatum" formControlName="vertrekDatumControl">
                            <mat-datepicker-toggle matSuffix [for]="vertrekdatum"></mat-datepicker-toggle>
                            <mat-datepicker #vertrekdatum></mat-datepicker>
                            <mat-error *ngIf="reserveringsForm.get('vertrekDatumControl').hasError('required')">
                                {{ 'prijzen-26' | translate}}
                                <strong>{{ 'prijzen-8' | translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Row Aantal volwassenen - Aantal kinderen -->
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-27' | translate}} *</mat-label>
                            <input type="number" matInput formControlName="aantalVolwassenenControl"
                                placeholder="Vul hier het aantal volwassenenen in">
                            <mat-error *ngIf="reserveringsForm.get('aantalVolwassenenControl').hasError('required')">
                                {{ 'prijzen-28' | translate}}
                                <strong>{{ 'prijzen-8' | translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-29' | translate}} *</mat-label>
                            <input type="number" matInput formControlName="aantalKinderenControl"
                                placeholder="Vul hier het aantal kinderen in">
                            <mat-error *ngIf="reserveringsForm.get('aantalKinderenControl').hasError('required')">
                                {{ 'prijzen-30' | translate}}
                                <strong>{{ 'prijzen-8' | translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Row Appartementsoort -->
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-select name="option" [required]="true" formControlName="appartementControl" placeholder="Kies een appartement">
                                <mat-option [required]="true" *ngFor="let option of dropdownOptions" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="reserveringsForm.get('appartementControl').hasError('required')">
                                Appartement is
                                <strong>{{ 'prijzen-8' | translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <p></p>

                <!-- Row Opmerkingen -->
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'prijzen-31' | translate}}</mat-label>
                            <textarea type="vraagopmerking" rows="5" matInput formControlName="opmerkingenControl"
                                placeholder="Vul hier je opmerking in">
                            </textarea>
                            <mat-error *ngIf="reserveringsForm.get('opmerkingenControl').hasError('maxlength')">
                                {{ 'prijzen-32' | translate}}
                            </mat-error>
                            <mat-error *ngIf="reserveringsForm.get('opmerkingenControl').hasError('minlength')">
                                {{ 'prijzen-33' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <p></p>
                <p class="error" *ngIf="this.datumsOngeldig">
                    {{ 'prijzen-34' | translate}}
                  </p>
                  <p class="error" *ngIf="this.datumsVoorVandaag">
                    {{ 'prijzen-35' | translate}}
                  </p>

                  <p></p>
                <re-captcha id="recaptcha" name="recaptcha" formControlName="tokenControl" required></re-captcha>
                <p></p>
                
                <a class="btn btn-xl btn-outline-light" (click)="verzendFormulier()">
                    {{ 'prijzen-36' | translate}}
                </a>

            </form>
        </div>
    </div>
</div>