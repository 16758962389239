<div class="container spacingcontainer">
    <div class="row">
        <div class="col-md-12">
            <div class="row contactcard">
                <div class="col-md-4 cardcolor padding">
                    <h3>
                        {{ 'contact-1' | translate }}
                    </h3>
                    <p>
                        {{ 'contact-2' | translate }}
                    </p>

                    <div class="spacing"></div>

                    <div class="row iconbox">
                        <div class="col-md-2 marginbottom10">
                            <span class="roundborder">
                                <i class="fas fa-map-marker-alt"></i>
                            </span>
                        </div>
                        <div class="col-md-10">
                            <p>
                                <b>{{ 'contact-3' | translate }}</b>
                            </p>
                            <p>
                                Dijkstelweg 21
                            </p>
                            <p>
                                3253TA Ouddorp
                            </p>
                            <p>
                                Nederland
                                <a href="https://goo.gl/maps/P88EysbU97w173oz6" target="_blank">
                                    <i class="fas fa-external-link-alt greylinkcolor"></i>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div class="row iconbox">
                        <div class="col-md-2 marginbottom10">
                            <span class="roundborder">
                                <i class="fas fa-phone-alt"></i>
                            </span>
                        </div>
                        <div class="col-md-10">
                            <p>
                                <b>{{ 'contact-4' | translate }}</b>
                            </p>
                            <p>
                                <a href="tel:+31621964707" class="deadlink">+31 (0)6 21964707 <i class="fas fa-external-link-alt greylinkcolor"></i></a>
                            </p>
                        </div>
                    </div>
                    <div class="row iconbox">
                        <div class="col-md-2 marginbottom10">
                            <span class="roundborder">
                                <i class="fas fa-envelope"></i>
                            </span>
                        </div>
                        <div class="col-md-10">
                            <p>
                                <b>{{ 'contact-5' | translate }}</b>
                            </p>
                            <p>
                                <a href="mailto:info@lodge21ouddorp.nl" class="deadlink">info@lodge21ouddorp.nl <i class="fas fa-external-link-alt greylinkcolor"></i></a>
                            </p>
                        </div>
                    </div>
                    <div class="row iconbox">
                        <div class="col-md-2 marginbottom10">
                            <span class="roundborder">
                                <i class="fas fa-globe-europe"></i>
                            </span>
                        </div>
                        <div class="col-md-10">
                            <p>
                                <b>{{ 'contact-6' | translate }}</b>
                            </p>
                            <p>
                                www.lodge21ouddorp.nl
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 padding">
                    <h2>{{ 'contact-7' | translate }}</h2>
                    <p>
                        {{ 'contact-8' | translate }}
                    </p>
                    <form [formGroup]="contactForm" autocomplete="off">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'contact-9' | translate }}</mat-label>
                                    <input type="naam" matInput formControlName="naamControl">
                                    <mat-error *ngIf="contactForm.get('naamControl').hasError('required')">
                                        {{ 'contact-10' | translate }}<strong>{{ 'contact-11' | translate }}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="contactForm.get('naamControl').hasError('minlength')">
                                        {{ 'contact-12' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="contactForm.get('naamControl').hasError('maxlength')">
                                        {{ 'contact-13' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'contact-14' | translate }}</mat-label>
                                    <input type="email" matInput formControlName="emailControl">
                                    <mat-error *ngIf="contactForm.get('emailControl').hasError('required')">
                                        {{ 'contact-15' | translate }}<strong>{{ 'contact-11' | translate }}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="contactForm.get('emailControl').hasError('email')">
                                        {{ 'contact-16' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="contactForm.get('emailControl').hasError('maxlength')">
                                        {{ 'contact-17' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'contact-18' | translate}}</mat-label>
                                    <textarea type="vraagopmerking" rows="10" matInput formControlName="vraagControl">
                                    </textarea>
                                    <mat-error *ngIf="contactForm.get('vraagControl').hasError('required')">
                                        {{ 'contact-19' | translate}}<strong>{{ 'contact-11' | translate}}</strong>
                                    </mat-error>
                                    <mat-error *ngIf="contactForm.get('vraagControl').hasError('minlength')">
                                        {{ 'contact-20' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <p></p>
                        <re-captcha id="recaptcha" name="recaptcha" formControlName="tokenControl" required></re-captcha>
                        <p></p>

                        <a class="btn btn-xl btn-outline-light" (click)="verzendFormulier()">
                            {{ 'contact-21' | translate}}
                        </a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container containerwithoutpadding">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28724.492369160682!2d3.892280892843549!3d51.81287380142161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c4f98dc1d7ead5%3A0x8b3f4fa36c2f143a!2sLodge21%20Ouddorp!5e0!3m2!1snl!2snl!4v1638904908700!5m2!1snl!2snl" 
        allowfullscreen="" 
        class="googlemaps">
    </iframe>
</div>