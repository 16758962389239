import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../services/authentication.service';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { LoginResponse } from '../shared/loginresponse.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private _lodgeBackendService: LodgeBackendService, private router: Router, private authService: AuthenticationService) {
    
  }

  loginForm = new FormGroup({
    gebruikersnaamControl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    wachtwoordControl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
  });

  public login(): void {
    if(!this.authService.isAuthenticated()) {
      localStorage.removeItem('acces_token');
    }
    if(this.loginForm.invalid){
      return;
    }
    let username = this.loginForm.controls['gebruikersnaamControl'].value;
    let password = this.loginForm.controls['wachtwoordControl'].value;

    let body = 'client_id=' + username + '&client_secret=' + password + '&grant_type=client_credentials&scope=API_Identity';

    this._lodgeBackendService.loginWebApi(body).toPromise().then(result => {
      let loginResponse = new LoginResponse().deserialize(result);
      localStorage.removeItem('acces_token');
      localStorage.setItem('acces_token', loginResponse.access_token);
      this.router.navigateByUrl('admin');
    }).catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'De combinatie gebruikersnaam en wachtwoord is onjuist',
        showConfirmButton: true,
        allowOutsideClick: true,
      })
    });
  }
}
