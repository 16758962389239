import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { AvailableReservationList } from '../shared/availablereservation.model';
import { map } from "rxjs/operators"
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-vakantieappartementen',
  templateUrl: './vakantieappartementen.component.html',
  styleUrls: ['./vakantieappartementen.component.scss']
})
export class VakantieappartementenComponent implements AfterViewInit {
  public heeftBeschikbareAppartementCallGedaan: boolean = false;
  public beschikbareAppartementen: AvailableReservationList;
  public datumsOngeldig: boolean = false;
  public datumsVoorVandaag: boolean = false;
  public dropdownOptions: any = [];
  private queryParams: ActivatedRoute;

  constructor(private _lodgeBackendService: LodgeBackendService, private translateService: TranslateService, params: ActivatedRoute, @Inject(DOCUMENT) document) {
    this.queryParams = params;
    translateService.get('huizen-10').subscribe(value => {
      this.dropdownOptions =  
      [
        value, 
        'Zilt', 
        'Sand'
      ];
      this.vakantieappartementenForm.get('aantalpersonenControl').setValue(this.dropdownOptions[0]);
    });
  }
  
  ngAfterViewInit(): void {
    let aankomstdatum = this.queryParams.params.pipe(map(p => p.aankomstdatum));
    let vertrekdatum = this.queryParams.params.pipe(map(p => p.vertrekdatum));
    if (aankomstdatum !== null && vertrekdatum !== null) {
      aankomstdatum.subscribe(result => {
        if(result !== undefined){
          this.vakantieappartementenForm.get('aankomstdatumControl').setValue(new Date(result));
        }
      });
      vertrekdatum.subscribe(result => {
        if(result !== undefined){
          this.vakantieappartementenForm.get('vertrekdatumControl').setValue(new Date(result));
        }
      });
      if(this.vakantieappartementenForm.get('aankomstdatumControl').value !== null &&
        this.vakantieappartementenForm.get('vertrekdatumControl').value !== null) {
          this.zoekAppartementen();
        }
    }
  }

  vakantieappartementenForm = new FormGroup({
    aankomstdatumControl: new FormControl('', [Validators.required]),
    vertrekdatumControl: new FormControl('', [Validators.required]),
    aantalpersonenControl: new FormControl('')
  });

  public zoekAppartementen(): void {
    if (this.vakantieappartementenForm.invalid) {
      return;
    }
    let aankomstdatumInt = new Date(this.vakantieappartementenForm.controls['aankomstdatumControl'].value).getTime();
    let vertrekdatumInt = new Date(this.vakantieappartementenForm.controls['vertrekdatumControl'].value).getTime();
    let vandaagdatumInt = new Date().getTime();
    if(aankomstdatumInt >= vertrekdatumInt) {
      this.datumsOngeldig = true;
      return;
    }
    else {
      this.datumsOngeldig = false;
    }
    if((aankomstdatumInt <= vandaagdatumInt) ||
      (vertrekdatumInt <= vandaagdatumInt)) {
      this.datumsVoorVandaag = true;
      return;
    }
    else {
      this.datumsVoorVandaag = false;
    }
    var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';
    var aankomstdatum = moment(this.vakantieappartementenForm.controls['aankomstdatumControl'].value).format(localFormat);
    var vertrekdatum = moment(this.vakantieappartementenForm.controls['vertrekdatumControl'].value).format(localFormat);
    var appartement: string;
    if(this.vakantieappartementenForm.controls['aantalpersonenControl'].value === 'Zilt'){
      appartement = 'Zilt';
    }
    else if(this.vakantieappartementenForm.controls['aantalpersonenControl'].value === 'Sand'){
      appartement = 'Sand';
    }
    else {
      appartement = null;
    }
    // Als de featureflag aan staat, moeten we alleen Zilt tonen. Dit vragen we op in backend
    if(environment.alleenZiltTonen) {
      appartement = 'Zilt';
    }

    this._lodgeBackendService.getReservationsWithDate(aankomstdatum, vertrekdatum, appartement).toPromise().then(result => {
      this.beschikbareAppartementen = new AvailableReservationList().deserialize(result);
      this.heeftBeschikbareAppartementCallGedaan = true;
    }).catch(error => {
      this.heeftBeschikbareAppartementCallGedaan = false;
      console.error(error);
    });
  }
}
