import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LodgeBackendService } from './lodge-backend.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public _lodgeBackendService: LodgeBackendService, public auth: AuthenticationService, public router: Router) {}

    canActivate(): boolean {
        if (localStorage.getItem('acces_token') === null) {
            this.NavigateAndError();
        }
        if (!this.auth.isAuthenticated()) {
            this.NavigateAndError();
        }
        this._lodgeBackendService.checkAccess().toPromise().then(result => {
            if(result.toString() !== 'true') {
                this.NavigateAndError();
            }
        }).catch(error => {
            this.NavigateAndError();
        })
        return true;
    }

    private NavigateAndError(): boolean {
        this.router.navigate(['login']);
        return false;
    }
}