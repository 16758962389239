<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h2>
                {{ 'huizen-1' | translate }}
            </h2>
            <p>
                {{ 'huizen-2' | translate }}
            </p>
            <p>
                {{ 'huizen-3' | translate }}
            </p>
        </div>
    </div>
</div>

<div class="banner extraspacing">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h4>{{ 'huizen-4' | translate }}</h4>
                <p>{{ 'huizen-5' | translate }}</p>
                <form [formGroup]="vakantieappartementenForm" autocomplete="off">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field class="fullwidth">
                                <mat-label>{{ 'huizen-6' | translate }}</mat-label>
                                <input matInput [matDatepicker]="aankomstdatum" formControlName="aankomstdatumControl">
                                <mat-datepicker-toggle matSuffix [for]="aankomstdatum"></mat-datepicker-toggle>
                                <mat-datepicker #aankomstdatum></mat-datepicker>

                                <mat-error class="validationerror"
                                    *ngIf="vakantieappartementenForm.get('aankomstdatumControl').hasError('required')">
                                    {{ 'huizen-7' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="fullwidth">
                                <mat-label>{{ 'huizen-8' | translate }}</mat-label>
                                <input matInput [matDatepicker]="vertrekdatum" formControlName="vertrekdatumControl">
                                <mat-datepicker-toggle matSuffix [for]="vertrekdatum"></mat-datepicker-toggle>
                                <mat-datepicker #vertrekdatum></mat-datepicker>

                                <mat-error class="validationerror"
                                    *ngIf="vakantieappartementenForm.get('vertrekdatumControl').hasError('required')">
                                    {{ 'huizen-9' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <mat-select name="option"
                                formControlName="aantalpersonenControl">
                                <mat-option *ngFor="let option of dropdownOptions" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <p></p>                    
                    <p class="error" *ngIf="this.datumsOngeldig">
                        {{ 'huizen-11' | translate }}
                    </p>
                    <p class="error" *ngIf="this.datumsVoorVandaag">
                        {{ 'huizen-12' | translate }}
                    </p>
                    <a class="btn btn-xl btn-outline-light" (click)="zoekAppartementen()">
                        {{ 'huizen-13' | translate }}
                    </a>
                </form>
            </div>
            <!-- Resultaat van zoekopdracht -->
            <div class="col-md-12 extraspacing" *ngIf="this.heeftBeschikbareAppartementCallGedaan">
                <div *ngFor="let appartement of beschikbareAppartementen.AvailableReservations">
                    <div class="card cardresult">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <img src="../../assets/foto's/IMG_2839.JPG"
                                        *ngIf="appartement.appartement === 'Zilt'" class="searchresult-image"
                                        alt="Zilt" />
                                    <img src="../../assets/foto's/Sand/sand38-min.jpeg"
                                        *ngIf="appartement.appartement === 'Sand'" class="searchresult-image"
                                        alt="Sand">
                                </div>
                                <div class="col-md-9">
                                    <p>{{ 'huizen-14' | translate }} <b>{{ appartement.appartement }}</b> {{ 'huizen-15' | translate }}</p>
                                    <p>{{ 'huizen-6' | translate }}: {{ appartement.aankomstdatum | date: 'dd-MM-yyyy' }} </p>
                                    <p>{{ 'huizen-8' | translate }}: {{ appartement.vertrekdatum | date: 'dd-MM-yyyy' }} </p>
                                    <a class="btn btn-xl btn-outline-light bottommargin" *ngIf="appartement.appartement === 'Zilt'"
                                        routerLink="/vakantieappartementen/zilt">
                                        {{ 'huizen-16' | translate }} {{ appartement.appartement }}
                                    </a>
                                    <a class="btn btn-xl btn-outline-light bottommargin" *ngIf="appartement.appartement === 'Sand'"
                                        routerLink="/vakantieappartementen/sand">
                                        {{ 'huizen-16' | translate }} {{ appartement.appartement }}
                                    </a>
                                    &nbsp;
                                    <a class="btn btn-xl btn-outline-light bottommargin" routerLink="/prijzen-en-reserveren">
                                        {{ 'huizen-17' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.heeftBeschikbareAppartementCallGedaan && this.beschikbareAppartementen.AvailableReservations.length === 0">
                    <div class="card cardresult">
                        <div class="card-body">
                            {{ 'huizen-21' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h4>
                {{ 'huizen-18' | translate }}
            </h4>
            <p>
                {{ 'huizen-19' | translate }}
            </p>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col-md-6">
            <div class="card h-100">
                <img src="../../assets/foto's/IMG_2839.JPG" class="card-img-top" alt="Zilt" />
                <div class="card-body">
                    <h5 class="card-title">Zilt</h5>
                    <p class="card-text">
                        {{ 'home-12' | translate }}
                    </p>
                </div>
                <div class="card-footer">
                    <small class="text-muted">
                        <a class="btn btn-xl btn-outline-light" routerLink="/vakantieappartementen/zilt">
                            {{ 'huizen-20' | translate }} Zilt
                        </a>
                    </small>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card h-100">
                <img src="../../assets/foto's/Sand/sand38-min.jpeg" class="card-img-top" alt="Sand">
                <div class="card-body">
                    <h5 class="card-title">Sand</h5>
                    <p class="card-text">
                        {{ 'home-13' | translate }}
                    </p>
                </div>
                <div class="card-footer">
                    <small class="text-muted">
                        <a class="btn btn-xl btn-outline-light" routerLink="/vakantieappartementen/sand">
                            {{ 'huizen-20' | translate }} Sand
                        </a>
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>

<span class="spacing"></span>