<mat-tab-group>
    <!-- Tab 1 -->
    <mat-tab [label]="'kalender-26' | translate">
        <div class="container">
            <p></p>
            <h5>
                {{ 'kalender-1' | translate}}
            </h5>
            <p></p>
            <div class="row calendar-header-spacing">
                <div class="col-md-1 nofullwidth">
                    <button class="btn btn-link" (click)="incrementMonth(-1)"><span class="arrow left"></span></button>
                </div>
                <div class="col-md-10 text-center nofullwidth">
                    <h4 class="appartement-text">
                        Zilt - {{months[month-1]}} - {{year}}
                    </h4>
                </div>
                <div class="col-md-1 nofullwidth">
                    <button class="btn btn-link" (click)="incrementMonth(+1)"><span class="arrow right"></span></button>
                </div>
            </div>
            <div class="row no-gutters weekdays-style">
                <div class="week-day" *ngFor="let date of weekdays">
                    <span class="weekday">{{date}}</span>
                </div>
            </div>
            <div class="week" *ngFor="let week of daysZilt">
                <div class="day" [class.muted]="!day.isMonth" *ngFor="let day of week"
                    [ngClass]="{'start-halfday-booked': day.isAankomstDag, 'booked-day': day.isGeboekteDag, 'end-halfday-booked': day.isVertrekDag}">
                    {{day.day}}
                </div>
            </div>
            <div class="loader-margin" *ngIf="daysZilt === null || daysZilt === undefined">
                <div class="row loaderbox">
                    <div class="col-md-6">
                        <p></p>
                        <mat-spinner class="loader"></mat-spinner>
                    </div>
                    <div class="col-md-6">
                        <p class="beschikbaarheid-text">
                            {{ 'kalender-28' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <!-- Tab 2 -->
    <mat-tab [label]="'kalender-27' | translate" *ngIf="!alleenZiltTonen">

        <div class="container">
            <p></p>
            <h5>
                {{ 'kalender-2' | translate}}
            </h5>
            <p></p>
            <div class="row calendar-header-spacing">
                <div class="col-md-1 nofullwidth">
                    <button class="btn btn-link" (click)="incrementMonth(-1)"><span class="arrow left"></span></button>
                </div>
                <div class="col-md-10 text-center nofullwidth">
                    <h4 class="appartement-text">
                        Sand - {{months[month-1]}} - {{year}}
                    </h4>
                </div>
                <div class="col-md-1 nofullwidth">
                    <button class="btn btn-link" (click)="incrementMonth(+1)"><span class="arrow right"></span></button>
                </div>
            </div>
            <div class="row no-gutters weekdays-style">
                <div class="week-day" *ngFor="let date of weekdays">
                    <span class="weekday">{{date}}</span>
                </div>
            </div>
            <div class="week" *ngFor="let week of daysSand">
                <div class="day" [class.muted]="!day.isMonth" *ngFor="let day of week"
                    [ngClass]="{'start-halfday-booked': day.isAankomstDag, 'booked-day': day.isGeboekteDag, 'end-halfday-booked': day.isVertrekDag}">
                    {{day.day}}
                </div>
            </div>
            <div class="loader-margin" *ngIf="daysSand === null || daysSand === undefined">
                <div class="row loaderbox">
                    <div class="col-md-6">
                        <p></p>
                        <mat-spinner class="loader"></mat-spinner>
                    </div>
                    <div class="col-md-6">
                        <p class="beschikbaarheid-text">
                            {{ 'kalender-28' | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </mat-tab>
</mat-tab-group>

<p></p>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <table>
                <tr>
                    <td>
                        <div class="day fullwidth">
                            1
                        </div>
                    </td>
                    <td>
                        {{ 'kalender-3' | translate}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="day booked-day fullwidth">
                            2
                        </div>
                    </td>
                    <td>
                        {{ 'kalender-4' | translate}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="day end-halfday-booked fullwidth">
                            3
                        </div>
                    </td>
                    <td>
                        {{ 'kalender-5' | translate}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="day start-halfday-booked fullwidth">
                            4
                        </div>
                    </td>
                    <td>
                        {{ 'kalender-6' | translate}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>