import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { PrijzenList } from '../shared/prijs.model';

@Component({
  selector: 'app-vakantieappartement-sand',
  templateUrl: './vakantieappartement-sand.component.html',
  styleUrls: ['./vakantieappartement-sand.component.scss']
})
export class VakantieappartementSandComponent {
  public prijzenSand: PrijzenList;
  public prijzenSandSpecial: PrijzenList;
  public currentLanguage: string;

  constructor(private _lodgeBackendService: LodgeBackendService, translateService: TranslateService) {
    this.getPrijzenSand();
    this.currentLanguage = translateService.currentLang;
  }

  public getPrijzenSand(): void {
    this._lodgeBackendService.getPrijzen('Sand').subscribe(val => {
      const result = new PrijzenList().deserialize(val);
      this.prijzenSand = new PrijzenList().deserialize(result.Prijzen.filter(p => !p.isSpecialDate));
      this.prijzenSandSpecial = new PrijzenList().deserialize(result.Prijzen.filter(p => p.isSpecialDate));
    });
  }

  imageObject: Array<object> = [    
    {
      image: '../../assets/foto-carousel/sand/sand38-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand38-min.jpeg',
    },   
    {
      image: '../../assets/foto-carousel/sand/sand26-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand26-min.jpeg',
    },    
    {
      image: '../../assets/foto-carousel/sand/sand20-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand20-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand2-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand2-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand3-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand3-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand8-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand8-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand9-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand9-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand12-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand12-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand13-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand13-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand15-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand15-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand18-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand18-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand19-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand19-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand22-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand22-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand24-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand24-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand25-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand25-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand27-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand27-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand28-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand28-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand32-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand32-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand35-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand35-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand36-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand36-min.jpeg',
    },
    {
      image: '../../assets/foto-carousel/sand/sand37-min.jpeg',
      thumbImage: '../../assets/foto-carousel/sand/sand37-min.jpeg',
    }
  ];
}
