<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h2>{{ 'sand-1' | translate}}</h2>
            <p>
                {{ 'sand-2' | translate}}
            </p>
        </div>
    </div>
</div>

<div class="container relative extraspacing">
    <div class="colordiv"></div>
    <div class="spacing"></div>
    <div class="row colordiv-height">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Sand/sand38-min.jpeg">
                </div>
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Sand/sand22-min.jpeg">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Sand/sand32-min.jpeg">
                </div>
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Sand/sand20-min.jpeg">
                </div>
            </div>
            <div class="spacing"></div>
        </div>
        <div class="col-md-6">
            <h3>
                {{ 'sand-3' | translate}}
            </h3>
            <p>
                {{ 'sand-4' | translate}}
            </p>
            <a class="btn btn-xl btn-outline-light" routerLink="/prijzen-en-reserveren">
                {{ 'sand-5' | translate}}
            </a>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h3>
                {{ 'sand-6' | translate}}
            </h3>
            <p>
                {{ 'sand-7' | translate}}
            </p>
        </div>
    </div>

    <p></p>

    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'sand-8' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <p>
                {{ 'sand-9' | translate}}
            </p>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-3">
            <h4>{{ 'sand-10' | translate}}</h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <b>
                {{ 'sand-11' | translate}}
            </b>
            <p>
                {{ 'sand-12' | translate}}
            </p>
            <b>
                {{ 'sand-13' | translate}}
            </b>
            <p>
                {{ 'sand-14' | translate}}
            </p>
            <b>
                {{ 'sand-15' | translate}}
            </b>
            <p>
                {{ 'sand-16' | translate}}
            </p>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'sand-17' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-6">
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-18' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-19' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-20' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-21' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-22' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-23' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-24' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-25' | translate}}</p>
                </div>
                <div class="col-md-6">
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-26' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-27' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-28' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-29' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-30' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-31' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-32' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'sand-33' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'sand-34' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <table class="table table-striped" *ngIf="this.prijzenSand !== undefined">
                    <thead>
                        <tr>
                            <th scope="col">{{ 'sand-35' | translate}}</th>
                            <th scope="col">{{ 'sand-36' | translate}}</th>
                            <th scope="col">{{ 'sand-37' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prijs of this.prijzenSand.Prijzen | orderBy:indexValue">
                            <td>
                                <span *ngIf="currentLanguage === 'nl'">{{ prijs.datumNl }}</span>
                                <span *ngIf="currentLanguage === 'en'">{{ prijs.datumEng }}</span>
                                <span *ngIf="currentLanguage === 'de'">{{ prijs.datumDe }}</span>
                            </td>
                            <td>
                                <span *ngIf="prijs.periode === 'Midweek'">
                                    {{ 'zilt-41' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Week'">
                                    {{ 'zilt-42' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Weekend'">
                                    {{ 'zilt-40' | translate}}
                                </span>
                            </td>
                            <td>{{ prijs.prijs }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'sand-38' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <table class="table table-striped" *ngIf="this.prijzenSandSpecial !== undefined">
                    <thead>
                        <tr>
                            <th scope="col">{{ 'sand-35' | translate}}</th>
                            <th scope="col">{{ 'sand-36' | translate}}</th>
                            <th scope="col">{{ 'sand-37' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prijs of this.prijzenSandSpecial.Prijzen | orderBy:indexValue">
                            <td>
                                <span *ngIf="currentLanguage === 'nl'">{{ prijs.datumNl }}</span>
                                <span *ngIf="currentLanguage === 'en'">{{ prijs.datumEng }}</span>
                                <span *ngIf="currentLanguage === 'de'">{{ prijs.datumDe }}</span>
                            </td>
                            <td>
                                <span *ngIf="prijs.periode === 'Midweek'">
                                    {{ 'zilt-41' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Week'">
                                    {{ 'zilt-42' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Weekend'">
                                    {{ 'zilt-40' | translate}}
                                </span>
                            </td>
                            <td>{{ prijs.prijs }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'sand-39' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <p>
                    {{ 'sand-40' | translate}}
                </p>
                <p></p>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{{ 'sand-41' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ 'sand-42' | translate}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'sand-43' | translate}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'sand-44' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="stripe"></div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <ng-image-slider [images]="imageObject" [arrowKeyMove]="true" speed="1" [animationSpeed]="0.4"
                paginationShow="true" infinite="true" #navigation></ng-image-slider>
        </div>
    </div>
</div>

<div class="bannercontainer">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>
                    {{ 'zilt-36' | translate}}
                </h3>
                <p></p>
                <a class="btn btn-xl btn-outline-light" routerLink="/vakantieappartementen">
                    {{ 'zilt-37' | translate}}
                </a>
            </div>
        </div>
    </div>
</div>