import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
    transform(value: any[]): any[] {
        return value.sort((n1,n2) => 
        {
          return n1.indexValue - n2.indexValue; 
        });
      }
}