import { Deserializable } from "./deserializable";

export class Reservation implements Deserializable {
  aankomstdatum: Date;
  vertrekdatum: Date;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class ReservationList implements Deserializable {
  Reservations: Reservation[] = [];

  deserialize(input: any) {
    Object.assign(this, input);
    this.Reservations = input;
    return this;
  }
}