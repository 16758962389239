import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { VakantieappartementZiltComponent } from './vakantieappartement-zilt/vakantieappartement-zilt.component';
import { VakantieappartementenComponent } from './vakantieappartementen/vakantieappartementen.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { OmgevingComponent } from './omgeving/omgeving.component';
import { ReserverenComponent } from './reserveren/reserveren.component';
import { AuthGuardService } from './services/auth-guardservice';
import { VakantieappartementSandComponent } from './vakantieappartement-sand/vakantieappartement-sand.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'vakantieappartementen/:aankomstdatum/:vertrekdatum/', component: VakantieappartementenComponent },
  { path: 'vakantieappartementen/:aankomstdatum/:vertrekdatum', component: VakantieappartementenComponent },
  { path: 'vakantieappartementen', component: VakantieappartementenComponent },
  { path: 'vakantieappartementen/zilt', component: VakantieappartementZiltComponent },
  { path: 'vakantieappartementen/sand', component: VakantieappartementSandComponent },
  { path: 'omgeving', component: OmgevingComponent },
  { path: 'prijzen-en-reserveren', component: ReserverenComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login', component: LoginComponent},
  { path: 'admin', component: AdminComponent, canActivate: [ AuthGuardService ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
