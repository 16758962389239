import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LodgeBackendService } from '../services/lodge-backend.service';
// When id of element === counters, start counting the counters
const counterId = 'counters';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  afstandBeachCounter: number = 1;
  afstandBeachCounterMaxValue: number = 1223;
  afstandCentrumCounter: number = 1;
  afstandCentrumMaxValue: number = 1085;
  afstandSupermarktCounter: number = 1;
  afstandSupermarktMaxValue: number = 1219;
  afstandStrandtentCounter: number = 1;
  afstandStrandtentMaxValue: number = 1183;
  datumsOngeldig: boolean = false;
  datumsVoorVandaag: boolean = false;

  constructor(private router: Router, public datepipe: DatePipe, private _lodgeBackendService: LodgeBackendService) {}

  ngAfterViewInit(): void {
    // Health check starten om API wakker te maken
    this._lodgeBackendService.checkHealth();
  }
  
  vakantieappartementenForm = new FormGroup({
    aankomstdatumControl: new FormControl('', [Validators.required,]),
    vertrekdatumControl: new FormControl('', [Validators.required])
  });

  @ViewChildren(counterId) sections: QueryList<ElementRef>;
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const activeSection = this.sections.toArray().findIndex(section => this.isElementInViewport(section.nativeElement));
    if (counterId.split(',')[activeSection] !== undefined) {
      this.RunCounters();
    }
  }

  public ControleerBeschikbaarheid(): void {
    if (this.vakantieappartementenForm.invalid) {
      return;
    }
    let aankomstdatumInt = new Date(this.vakantieappartementenForm.controls['aankomstdatumControl'].value).getTime();
    let vertrekdatumInt = new Date(this.vakantieappartementenForm.controls['vertrekdatumControl'].value).getTime();
    let vandaagdatumInt = new Date().getTime();
    if(aankomstdatumInt >= vertrekdatumInt) {
      this.datumsOngeldig = true;
      return;
    }
    else {
      this.datumsOngeldig = false;
    }
    if((aankomstdatumInt < vandaagdatumInt) ||
      (vertrekdatumInt <= vandaagdatumInt)) {
      this.datumsVoorVandaag = true;
      return;
    }
    else {
      this.datumsVoorVandaag = false;
    }
    var aankomstdatum: Date = new Date(this.vakantieappartementenForm.controls['aankomstdatumControl'].value);
    var vertrekdatum: Date = new Date(this.vakantieappartementenForm.controls['vertrekdatumControl'].value);
    this.router.navigateByUrl('/vakantieappartementen/' + this.datepipe.transform(aankomstdatum, 'MM-dd-yyyy') + '/' + this.datepipe.transform(vertrekdatum, 'MM-dd-yyyy'));
  }

  private isElementInViewport(el) {
    var rect = el.getBoundingClientRect();

    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public async RunCounters(): Promise<void> {
    const counterSpeed: number = 20;
    this.runBeachCounter(counterSpeed);
    this.runCentrumCounter(counterSpeed);
    this.runSupermarktCounter(counterSpeed);
    this.runStandtentCounter(counterSpeed);
  }

  private async runBeachCounter(delay: number): Promise<void> {
    while (this.afstandBeachCounter < this.afstandBeachCounterMaxValue) {
      this.afstandBeachCounter++;
      await this.delay(delay);
    }
  }

  private async runCentrumCounter(delay: number): Promise<void> {
    while (this.afstandCentrumCounter < this.afstandCentrumMaxValue) {
      this.afstandCentrumCounter++;
      await this.delay(delay);
    }
  }

  private async runSupermarktCounter(delay: number): Promise<void> {
    while (this.afstandSupermarktCounter < this.afstandSupermarktMaxValue) {
      this.afstandSupermarktCounter++;
      await this.delay(delay);
    }
  }

  private async runStandtentCounter(delay: number): Promise<void> {
    while (this.afstandStrandtentCounter < this.afstandStrandtentMaxValue) {
      this.afstandStrandtentCounter++;
      await this.delay(delay);
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Slider: https://www.freakyjolly.com/angular-responsive-image-slider-carousel-with-zoom-lightbox-popup-example/
  imageObject: Array<object> = [
    {
      image: '../../assets/foto-carousel/home/headerimage 1.jpeg',
      thumbImage: '../../assets/foto-carousel/home/headerimage 1.jpeg',
    },
    {
      image: '../../assets/foto-carousel/home/headerimage 2.jpeg',
      thumbImage: '../../assets/foto-carousel/home/headerimage 2.jpeg',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2828.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2828.JPG',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2840.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2840.JPG',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2844.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2844.JPG',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2817.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2817.JPG',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2823.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2823.JPG',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2834.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2834.JPG',
    },
    {
      image: '../../assets/foto-carousel/home/IMG_2786.JPG',
      thumbImage: '../../assets/foto-carousel/home/IMG_2786.JPG',
    }
  ];
}
