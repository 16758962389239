import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgImageSliderModule } from 'ng-image-slider';
import { ContactComponent } from './contact/contact.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { ReserverenComponent } from './reserveren/reserveren.component';
import { OmgevingComponent } from './omgeving/omgeving.component';
import { VakantieappartementZiltComponent } from './vakantieappartement-zilt/vakantieappartement-zilt.component';
import { VakantieappartementenComponent } from './vakantieappartementen/vakantieappartementen.component';
import { MatTabsModule } from '@angular/material/tabs';
import { LodgeBackendService } from './services/lodge-backend.service';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuardService } from './services/auth-guardservice';
import { AuthenticationService } from './services/authentication.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { I18nModule } from './i18n/i18n.module';
import { KalenderComponent } from './kalender/kalender.component';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VakantieappartementSandComponent } from './vakantieappartement-sand/vakantieappartement-sand.component';
import { OrderByPipe } from './shared/order-by.pipe';

registerLocaleData(localeNL, 'NL');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    ReserverenComponent,
    OmgevingComponent,
    VakantieappartementZiltComponent,
    VakantieappartementenComponent,
    LoginComponent,
    AdminComponent,
    KalenderComponent,
    VakantieappartementSandComponent,
    OrderByPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    HttpClientModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgImageSliderModule,
    MatTabsModule,
    MatCheckboxModule,
    I18nModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    LodgeBackendService,
    MatDatepickerModule,
    AuthGuardService,
    AuthenticationService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'NL'
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
