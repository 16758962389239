<div class="container extraspacing">
    <div class="centercontent">
        <form class="form" [formGroup]="loginForm" autocomplete="off">
            <h3>Inloggen</h3>
            <p></p>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="fill" class="fullwidth">
                        <mat-label>Gebruikersnaam *</mat-label>
                        <input type="naam" matInput formControlName="gebruikersnaamControl"
                            placeholder="Vul hier je gebruikersnaam in">
                        <mat-error *ngIf="loginForm.get('gebruikersnaamControl').hasError('required')">
                            Gebruikersnaam is <strong>verplicht</strong>
                        </mat-error>
                        <mat-error *ngIf="loginForm.get('gebruikersnaamControl').hasError('minlength')">
                            Gebruikersnaam is te kort
                        </mat-error>
                        <mat-error *ngIf="loginForm.get('gebruikersnaamControl').hasError('maxlength')">
                            Gebruikersnaam is te lang
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <p></p>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="fill" class="fullwidth">
                        <mat-label>Wachtwoord *</mat-label>
                        <input type="password" matInput formControlName="wachtwoordControl"
                            placeholder="Vul hier je wachtwoord in">
                        <mat-error *ngIf="loginForm.get('wachtwoordControl').hasError('required')">
                            Wachtwoord is <strong>verplicht</strong>
                        </mat-error>
                        <mat-error *ngIf="loginForm.get('wachtwoordControl').hasError('minlength')">
                            Wachtwoord is te kort
                        </mat-error>
                        <mat-error *ngIf="loginForm.get('wachtwoordControl').hasError('maxlength')">
                            Wachtwoord is te lang
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <p></p>
            <a class="btn btn-xl btn-outline-light" (click)="login()">
                Login
            </a>
        </form>
    </div>
</div>