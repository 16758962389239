<!-- Bootstrap image carousel-->
<div id="carouselSliderImage" class="carousel slide" data-ride="carousel" data-interval="5000"
  data-keyboard="false" data-pause="false">
  <ol class="carousel-indicators zindex1">
    <li data-target="#carouselSliderImage" data-slide-to="0" class="active"></li>
    <li data-target="#carouselSliderImage" data-slide-to="1"></li>
    <li data-target="#carouselSliderImage" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="../../assets/foto's/headerimage 1.jpeg" alt="First slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="../../assets/foto's/headerimage 2.jpeg" alt="Second slide">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselSliderImage" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselSliderImage" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

<!-- Call to action reserveren -->
<div class="reserveren-box">
  <div class="container">
    <div class="row reservering-spacing">
      <form [formGroup]="vakantieappartementenForm" autocomplete="off" class="reservationform">
        <div class="col-md-3 datumselecteren reserveren-label">
          <h5><b>{{'home-1' | translate}}</b></h5>
        </div>
        <div class="col-md-3 datumselecteren">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'home-2' | translate}}</mat-label>
            <input matInput [matDatepicker]="aankomstpicker" formControlName="aankomstdatumControl">
            <mat-datepicker-toggle matSuffix [for]="aankomstpicker"></mat-datepicker-toggle>
            <mat-datepicker #aankomstpicker></mat-datepicker>
            <mat-error *ngIf="vakantieappartementenForm.get('aankomstdatumControl').hasError('required')">
              {{'home-3' | translate}}
            </mat-error>
            <mat-error *ngIf="vakantieappartementenForm.get('aankomstdatumControl').hasError('dateValid')">
              {{'home-4' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 datumselecteren">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'home-5' | translate}}</mat-label>
            <input matInput [matDatepicker]="vertrekpicker" formControlName="vertrekdatumControl">
            <mat-datepicker-toggle matSuffix [for]="vertrekpicker"></mat-datepicker-toggle>
            <mat-datepicker #vertrekpicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-2 datumselecteren parent-on-bottom">
          <a class="btn btn-xl btn-outline-light child-on-bottom" (click)="ControleerBeschikbaarheid()">
            {{'home-6' | translate}}
          </a>
          <p></p>
        </div>
        <p class="error" *ngIf="this.datumsOngeldig">
          {{'home-7' | translate}}
        </p>
        <p class="error" *ngIf="this.datumsVoorVandaag">
          {{'home-8' | translate}}
        </p>
      </form>
    </div>
  </div>
</div>

<div class="container">
  <div class="row margin50">
    <div class="col-md-6">
      <h1>{{'home-9' | translate}}</h1>
      <p>{{'home-10' | translate}}</p>
    </div>
    <div class="col-md-6">
      <img src="../../assets/foto's/strand-ouddorp.jfif" alt="Strand Ouddorp" class="image-ouddorp" />
    </div>
  </div>
</div>

<!-- Banner -->
<section class="page-section mb-0 banner">
  <div class="container">
    <h2 class="page-section-heading text-center text-uppercase">{{'home-11' | translate}}</h2>
    <div class="divider-custom divider-light">
      <div class="divider-custom-line"></div>
      <div class="divider-custom-icon"><i class="fas fa-star icon-style"></i></div>
      <div class="divider-custom-line"></div>
    </div>
    <div class="row">
      <div class="col-lg-4 ms-auto">
        <p class="lead">
          <b>Zilt</b>
          <br />
          {{'home-12' | translate}}
        </p>
      </div>
      <div class="col-lg-4 me-auto">
        <p class="lead">
          <b>Sand</b>
          <br />
          {{'home-13' | translate}}
        </p>
      </div>
    </div>
    <div class="text-center mt-4">
      <a class="btn btn-xl btn-outline-light" routerLink="/vakantieappartementen">
        <i class="fas fa-home me-2"></i>
        {{'home-14' | translate}}
      </a>
    </div>
  </div>
</section>

<!-- Image carousel -->
<section class="container imagecarouselcontainer">
  <div class="row">
    <div class="col-md-12">
      <ng-image-slider [images]="imageObject" [arrowKeyMove]="true" speed="1" [animationSpeed]="0.4"
        paginationShow="true" infinite="true" #navigation></ng-image-slider>
    </div>
  </div>
</section>

<!-- Stripe -->
<div class="margin50"></div>
<div class="stripe"></div>
<div class="margin50"></div>

<!-- Quotes -->
<!-- <section class="pb-5 displaynone">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 col-xl-8 mx-auto">
        <div class="p-5 bg-white shadow rounded">
          Comment: Bootstrap carousel
          <div class="carousel slide" id="quoteCarousel" data-ride="carousel">
            Comment: Bootstrap carousel indicators [nav] 
            <ol class="carousel-indicators mb-0">
              <li class="active" data-target="#quoteCarousel" data-slide-to="0"></li>
              <li data-target="#quoteCarousel" data-slide-to="1"></li>
              <li data-target="#quoteCarousel" data-slide-to="2"></li>
            </ol>


            Comment:  Bootstrap inner [slides]
            <div class="carousel-inner px-5 pb-4">
              Comment: Carousel slide
              <div class="carousel-item active">
                <div class="media">
                  <div class="media-body ml-3">
                    <blockquote class="blockquote border-0 p-0">
                      <p class="font-italic lead"> <i class="fa fa-quote-left mr-3 text-success quote-icon"></i>Lorem ipsum dolor
                        sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.</p>
                      <footer class="blockquote-footer">
                        Someone famous in Ouddurp
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>

              Comment: Carousel slide
              <div class="carousel-item">
                <div class="media">
                  <div class="media-body ml-3">
                    <blockquote class="blockquote border-0 p-0">
                      <p class="font-italic lead"> <i class="fa fa-quote-left mr-3 text-success quote-icon"></i>Lorem ipsum dolor
                        sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.</p>
                      <footer class="blockquote-footer">
                        Someone famous in Ouddurp
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>

              Comment: Carousel slide
              <div class="carousel-item">
                <div class="media">
                  <div class="media-body ml-3">
                    <blockquote class="blockquote border-0 p-0">
                      <p class="font-italic lead"> <i class="fa fa-quote-left mr-3 text-success quote-icon"></i>Lorem ipsum dolor
                        sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.</p>
                      <footer class="blockquote-footer">
                        Someone famous in Ouddurp
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            Comment: Bootstrap controls [dots]
            <a class="carousel-control-prev width-auto" href="#quoteCarousel" role="button"
              data-slide="prev">
              <i class="fa fa-angle-left text-dark text-lg"></i>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next width-auto" href="#quoteCarousel" role="button"
              data-slide="next">
              <i class="fa fa-angle-right text-dark text-lg"></i>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->


<!-- Spacing -->
<div class="margin50"></div>
<div class="margin50"></div>
<div class="margin50"></div>

<!-- Counters -->
<section>
  <div #counters class="container wow">
    <div class="row">
      <div class="col-md-12 text-center">
        <h3>{{ 'home-15' | translate }}</h3>
        <p>{{ 'home-16' | translate }}</p>
      </div>
      <div class="col-md-3 text-center">
        <span class="counter">{{ afstandBeachCounter }}</span>
        <h4><span class="meter">{{ 'home-17' | translate}}</span><span class="rest-countertext"> {{ 'home-18' | translate }}</span></h4>
      </div>
      <div class="col-md-3 text-center">
        <span class="counter">{{ afstandCentrumCounter }}</span>
        <h4><span class="meter">{{ 'home-17' | translate}}</span><span class="rest-countertext"> {{ 'home-19' | translate }}</span></h4>
      </div>
      <div class="col-md-3 text-center">
        <span class="counter">{{ afstandSupermarktCounter }}</span>
        <h4><span class="meter">{{ 'home-17' | translate}}</span><span class="rest-countertext"> {{ 'home-20' | translate }}</span></h4>
      </div>
      <div class="col-md-3 text-center">
        <span class="counter">{{ afstandStrandtentCounter }}</span>
        <h4><span class="meter">{{ 'home-17' | translate}}</span><span class="rest-countertext"> {{ 'home-21' | translate }}</span></h4>
      </div>
    </div>
  </div>
</section>
<br /><br /><br /><br />

<!-- Beach image parallax -->
<section>
  <div class="parralax-image"></div>
</section>
<br /><br /><br /><br />

<!-- Beach animation -->
<div class="container">
  <div class="row">
    <div class="col-md-6">
      <h2>{{ 'home-22' | translate }}</h2>
      <p>
        {{ 'home-23' | translate }}
      </p>
    </div>
    <div class="col-md-5">
      <div class="container-sun">
        <svg class="svg-sun" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
          <circle cx="50" cy="50" r="35" id="sun"></circle>
        </svg>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>

<br /><br /><br />

<svg class="editorial" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 24 150 28" preserveAspectRatio="none">
  <defs>
    <path id="gentle-wave" d="M-160 44c30 0 
    58-18 88-18s
    58 18 88 18 
    58-18 88-18 
    58 18 88 18
    v44h-352z" />
  </defs>
  <g class="parallax">
    <use xlink:href="#gentle-wave" x="50" y="0" class="wave1" />
    <use xlink:href="#gentle-wave" x="50" y="3" class="wave2" />
    <use xlink:href="#gentle-wave" x="50" y="6" class="wave3" />
  </g>
</svg>

<link href="https://fonts.googleapis.com/css?family=Lato:100" rel="stylesheet">

<!-- Banner -->
<section>
  <div class="banner banner-bottom-home">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="seabox">
            <h2>{{ 'home-24' | translate }}</h2>
            <div class="line"></div>
            <p>{{ 'home-25' | translate }}</p>
            <a class="btn btn-xl btn-outline-light" routerLink="/prijzen-en-reserveren">
              {{ 'home-26' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>