import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {

    constructor(public jwtHelper: JwtHelperService) { }

    public isAuthenticated(): boolean {
        // Check whether the token is expired and return
        const token = localStorage.getItem('acces_token');
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }
}