import { Deserializable } from "./deserializable";

export class AvailableReservation implements Deserializable {
  aankomstdatum: Date;
  vertrekdatum: Date;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class AvailableReservationList implements Deserializable {
  AvailableReservations: AvailableReservation[] = [];

  deserialize(input: any) {
    Object.assign(this, input);
    this.AvailableReservations = input;
    return this;
  }
}