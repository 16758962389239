import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { Contactform } from '../shared/contactform.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  constructor(private _lodgeBackendService: LodgeBackendService) { }

  contactForm = new FormGroup({
    naamControl: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]),
    emailControl: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    vraagControl: new FormControl('', [Validators.required, Validators.minLength(5)]),
    tokenControl: new FormControl('', [Validators.required])
  });

  public verzendFormulier(): void {
    if(this.contactForm.invalid){
      return;
    }

    Swal.fire({
      title: 'Info',
      text: 'Het contactformulier wordt verzonden...',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    let contactform = new Contactform();
    contactform.Naam = this.contactForm.controls['naamControl'].value;
    contactform.Email = this.contactForm.controls['emailControl'].value;
    contactform.Bericht = this.contactForm.controls['vraagControl'].value;
    contactform.RecaptchaToken = this.contactForm.controls['tokenControl'].value;
    this.postFormToBackend(contactform);
  }

  postFormToBackend(contactform) {
    var result = this._lodgeBackendService.postContactform(contactform);
    return result.subscribe(val => {
      Swal.close();
      if (val === null || val === undefined) {
        this.throwErrorSwal();
      }
      Swal.fire({
        title: 'Info',
        text: 'Het contactformulier is succesvol verzonden.',
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: true,
      })
      this.contactForm.reset();
    }, err => {
      Swal.close();
      this.throwErrorSwal();
    });
  }

  throwErrorSwal() {
    Swal.fire({
      icon: 'error',
      title: 'Oeps...',
      text: 'Er is iets fout gegaan! Probeer het opnieuw...',
      showConfirmButton: true,
      allowOutsideClick: true,
    })
  }
}
