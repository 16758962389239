import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { Reservation, ReservationList } from '../shared/reservation.model';
import { Reservationform } from '../shared/reservationform.model';

@Component({
  selector: 'app-reserveren',
  templateUrl: './reserveren.component.html',
  styleUrls: ['./reserveren.component.scss']
})
export class ReserverenComponent {
  public datumsOngeldig: boolean = false;
  public datumsVoorVandaag: boolean = false;
  public dropdownOptions: any =
    [
      'Zilt',
      'Sand'
    ];

  constructor(private _lodgeBackendService: LodgeBackendService) { }

  reserveringsForm = new FormGroup({
    voornaamControl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    achternaamControl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
    emailControl: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    telefoonNrControl: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]),
    aankomstDatumControl: new FormControl('', [Validators.required]),
    vertrekDatumControl: new FormControl('', [Validators.required]),
    aantalVolwassenenControl: new FormControl('', [Validators.required]),
    aantalKinderenControl: new FormControl('', [Validators.required]),
    appartementControl: new FormControl('', Validators.minLength(1)),
    opmerkingenControl: new FormControl('', [Validators.minLength(2), Validators.maxLength(200)]),
    tokenControl: new FormControl('', [Validators.required])
  });

  public verzendFormulier(): void {
    if (this.reserveringsForm.invalid) {
      this.reserveringsForm.markAllAsTouched();
      return;
    }

    let aankomstdatumInt = new Date(this.reserveringsForm.controls['aankomstDatumControl'].value).getTime();
    let vertrekdatumInt = new Date(this.reserveringsForm.controls['vertrekDatumControl'].value).getTime();
    let vandaagdatumInt = new Date().getTime();
    if(aankomstdatumInt >= vertrekdatumInt) {
      this.datumsOngeldig = true;
      return;
    }
    else {
      this.datumsOngeldig = false;
    }
    if((aankomstdatumInt <= vandaagdatumInt) ||
      (vertrekdatumInt <= vandaagdatumInt)) {
      this.datumsVoorVandaag = true;
      return;
    }
    else {
      this.datumsVoorVandaag = false;
    }
    
    Swal.fire({
      title: 'Info',
      text: 'Het contactformulier wordt verzonden...',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    
    var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    let reservationform = new Reservationform();
    reservationform.Voornaam = this.reserveringsForm.controls['voornaamControl'].value;
    reservationform.Achternaam = this.reserveringsForm.controls['achternaamControl'].value;
    reservationform.Emailadres = this.reserveringsForm.controls['emailControl'].value;
    reservationform.Telefoonnummer = this.reserveringsForm.controls['telefoonNrControl'].value;
    reservationform.Aankomstdatum = moment(this.reserveringsForm.controls['aankomstDatumControl'].value).format(localFormat);
    reservationform.Vertrekdatum = moment(this.reserveringsForm.controls['vertrekDatumControl'].value).format(localFormat);
    reservationform.AantalVolwassenen = this.reserveringsForm.controls['aantalVolwassenenControl'].value;
    reservationform.AantalKinderen = this.reserveringsForm.controls['aantalKinderenControl'].value;
    reservationform.RecaptchaToken = this.reserveringsForm.controls['tokenControl'].value;
    if(this.reserveringsForm.controls['appartementControl'].value?.includes('Zilt')){
      reservationform.Appartement = "Zilt";
    }
    else {
      reservationform.Appartement = "Sand";
    }

    reservationform.Opmerkingen = this.reserveringsForm.controls['opmerkingenControl'].value;
    this.postFormToBackend(reservationform);
  }

  postFormToBackend(reservationform) {
    var result = this._lodgeBackendService.postReservationform(reservationform);
    return result.subscribe(val => {
      Swal.close();
      if (val === null || val === undefined) {
        this.throwErrorSwal();
      }
      Swal.fire({
        title: 'Info',
        text: 'Het reserveringsformulier is succesvol verzonden.',
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: true,
      })
      this.reserveringsForm.reset();
    }, err => {
      Swal.close();
      this.throwErrorSwal();
    });
  }

  throwErrorSwal() {
    Swal.fire({
      icon: 'error',
      title: 'Oeps...',
      text: 'Er is iets fout gegaan! Probeer het opnieuw...',
      showConfirmButton: true,
      allowOutsideClick: true,
    })
  }
}
