import { Deserializable } from "./deserializable";

export class LoginResponse implements Deserializable {
  access_token: string;
  expires_in: string;
  token_type: string;
  scope: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}