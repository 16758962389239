import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: string;
  alleenZiltTonen: boolean;
  
  constructor() {
    this.alleenZiltTonen = environment.alleenZiltTonen;
   }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear().toString();
  }
}
