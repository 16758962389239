// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// // LOCAL SETTINGS
// export const environment = {
//   production: false,
//   // apiEndpoint: 'https://api.lodge21ouddorp.nl:444/',
//   apiEndpoint: 'http://localhost:60903/',
//   key: 'c18ce20f-8cf5-4c30-b6d3-6fccdaaa2df1',
//   recaptcha: {
//     siteKey: '6Lc5TggeAAAAABmaVFs9hdOGYkk10y5Oye7lVvLf'
//   },
//   alleenZiltTonen: false
// };

// PRODUCTION SETTINGS
export const environment = {
  production: true,
  apiEndpoint: 'https://api.lodge21ouddorp.nl:444/',
  key: 'c18ce20f-8cf5-4c30-b6d3-6fccdaaa2df1',
  recaptcha: {
    siteKey: '6Lc5TggeAAAAABmaVFs9hdOGYkk10y5Oye7lVvLf'
  },
  alleenZiltTonen: false
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
