import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private translateService: TranslateService;

  constructor(translate: TranslateService) {
    this.translateService = translate;
  }

  public delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms));
  }

  public ChangeLanguageNederlands(): void {
    this.translateService.use('nl');
  }

  public ChangeLanguageEngels(): void {
    this.translateService.use('en');
  }

  public ChangeLanguageDuits(): void {
    this.translateService.use('de');
  }
}