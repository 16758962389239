import { Deserializable } from "./deserializable";

export class ReservationWithAuth implements Deserializable {
  id: number;
  aankomstdatum: Date;
  vertrekdatum: Date;
  appartement: string;
  naamOmschrijving: string;
  email: string;
  telefoonnummer: string;
  createdate: Date;
  updatedate: Date;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class ReservationWithAuthList implements Deserializable {
  Reservations: Array<ReservationWithAuth> = [];

  deserialize(input: any) {
    Object.assign(this, input);
    this.Reservations = input;
    return this;
  }
}