<section>
    <!-- Footer -->
    <footer class="footer text-white text-center">
      <!-- Grid container -->
      <div class="container p-4">
        <!--Grid row-->
        <div class="row textleft">
          <!--Grid column-->
          <div class="col-md-6 mt-md-0 mt-3 spacing">
            <h5 class="text-uppercase">{{ 'footer-1' | translate }}</h5>
            <p class="greytext no-margin-bottom">Dijkstelweg 21</p>
            <p class="greytext no-margin-bottom">3253TA Ouddorp</p>
            <p class="greytext no-margin-bottom">Nederland</p>
            <p class="greytext no-margin-bottom">
              <a class="greytext" href="tel:+31621964707">+31 (0)6 21964707</a>
            </p>
            <p class="greytext no-margin-bottom">
              <a class="greytext" href="mailto:info@lodge21ouddorp.nl">info@lodge21ouddorp.nl</a>
            </p>
          </div>
          <!--Grid column-->
          <div class="col-md-1"></div>
          <!--Grid column-->
          <div class="col-md-5 mb-md-0 mb-3">
            <h5 class="text-uppercase">{{ 'footer-2' | translate }}</h5>
  
            <ul class="list-unstyled mb-0">
              <li>
                <a class="greytext" routerLink="/">{{ 'menu-1' | translate }}</a>
              </li>
              <li>
                <a class="greytext" routerLink="/vakantieappartementen">{{ 'menu-2' | translate }}</a>
              </li>
              <li>
                &nbsp;&nbsp;&nbsp; <a class="greytext" routerLink="/vakantieappartementen/zilt">- {{ 'menu-3' | translate }}</a>
              </li>
              <li *ngIf="!alleenZiltTonen">
                &nbsp;&nbsp;&nbsp; <a class="greytext" routerLink="/vakantieappartementen/sand">- {{ 'menu-4' | translate }}</a>
              </li>
              <li>
                <a class="greytext" routerLink="/omgeving">{{ 'menu-5' | translate }}</a>
              </li>
              <li>
                <a class="greytext" routerLink="/prijzen-en-reserveren">{{ 'menu-6' | translate }}</a>
              </li>
              <li>
                <a class="greytext" routerLink="/contact">{{ 'menu-7' | translate }}</a>
              </li>
            </ul>
          </div>
          <!--Grid column-->
        </div>
      </div>
      <!-- Copyright -->
      <div class="text-center p-3 under-footer" style="color: grey;">
        © {{ currentYear }} | {{ 'footer-3' | translate }} |
        <a href="https://www.linkedin.com/in/robin-tanis-3b7032115" target="_blank" class="activehover">{{ 'footer-4' | translate }}</a>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </section>