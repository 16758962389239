import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-omgeving',
  templateUrl: './omgeving.component.html',
  styleUrls: ['./omgeving.component.scss']
})
export class OmgevingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
