import { Deserializable } from "./deserializable";

export class Prijs implements Deserializable {
  id: string;
  datumNl: string;
  datumEng: string;
  datumDe: string;
  prijs: string;
  periode: string;
  isSpecialDate: boolean;
  indexValue: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class PrijzenList implements Deserializable {
  Prijzen: Array<Prijs> = [];

  deserialize(input: any) {
    Object.assign(this, input);
    this.Prijzen = input;
    return this;
  }
}