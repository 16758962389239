import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Prijs, PrijzenList } from '../shared/prijs.model';

const httpOptions = {
    headers: new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Headers', '*')
};

const httpOptionsWithFromEncoded = {
    headers: new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Headers', '*')
};

@Injectable()
export class LodgeBackendService {
    private baseUrl: string = environment.apiEndpoint;
    private key: string = environment.key;

    constructor(private http: HttpClient) { }

    // Call om het contactformulier te verzenden
    postContactform(contactform) {
        return this.http.post(this.baseUrl + 'Contact/contactform/' + this.key, contactform, httpOptions);
    }

    // Call om het reserveringsformulier te verzenden
    postReservationform(reservationform) {
        return this.http.post(this.baseUrl + 'Contact/reservationform/' + this.key, reservationform, httpOptions);
    }

    // Call om de reserveringen op te halen van een specifieke appartement. Geen auth nodig, komen alleen datums terug
    getReservations(appartement) {
        return this.http.get(this.baseUrl + 'Reservation/reservations/' + appartement + '/' + this.key, httpOptions);
    }

    // Call om de reserveringen op te halen van een specifieke appartement en een uniek tijdstip. Geen auth nodig, komen alleen datums terug
    getReservationsWithDate(aankomstdatum, vertrekdatum, appartement) {
        return this.http.get(this.baseUrl + 'Reservation/reservationswithdate/' + aankomstdatum + '/' + vertrekdatum + '/' + this.key + '/' + appartement);
    }

    // Call om in te loggen in de webapi
    loginWebApi(loginform) {
        return this.http.post(this.baseUrl + 'connect/token', loginform, httpOptionsWithFromEncoded);
    }

    // Call om te controleren of de user ingelogd is. Auth vereist.
    checkAccess() {
        let authHeader = this.CreateAuthHeader();
        return this.http.get(this.baseUrl + 'Login/checklogin/' + this.key, authHeader);
    }

    // Call om ee reservering te posten. Auth vereist.
    postReservation(reservation) {
        let authHeader = this.CreateAuthHeader();
        return this.http.post(this.baseUrl + 'ReservationWithAuth/reservationWithAuth/' + this.key, reservation, authHeader);
    }

    // Call om alle reseveringen op te halen. Auth vereist.
    getAllReservations(showHistory) {
        let authHeader = this.CreateAuthHeader();
        return this.http.get(this.baseUrl + 'ReservationWithAuth/allreservationsWithAuth/' + showHistory + '/' + this.key, authHeader);
    }

    // Call om een reservering weg te gooien. Auth vereist.
    deleteReservation(reservationId) {
        let authHeader = this.CreateAuthHeader();
        return this.http.post(this.baseUrl + 'ReservationWithAuth/deletereservationWithAuth/' + reservationId + '/' + this.key, null, authHeader);
    }

    // Call om een reservering te updaten. Auth vereist.
    updateReservation(reservation) {
        let authHeader = this.CreateAuthHeader();
        return this.http.put(this.baseUrl + 'ReservationWithAuth/updatereserveringWithAuth/' + this.key, reservation, authHeader);
    }

    // Call om de api wakker te maken
    checkHealth(): void {
        this.http.get(this.baseUrl + 'health',{responseType: 'text'}).subscribe();
    }

    getPrijzen(appartement: string) {
        return this.http.get(this.baseUrl + 'Reservation/getprijzen/' + appartement + '/' + this.key, httpOptions);
    }

    putPrijzen(prijzen: Prijs[], appartement: string, isSpecialDate: boolean) {
        let authHeader = this.CreateAuthHeader();
        return this.http.put(this.baseUrl + 'ReservationWithAuth/putprijzenWithAuth/' + appartement + '/' + isSpecialDate + '/' + this.key, prijzen, authHeader);
    }

    // Methode voor het maken van een header met bearer token.
    CreateAuthHeader(): any {
        const httpOptionsWithAuthenticationHeaders = {
            headers: new HttpHeaders()
                .set('Access-Control-Allow-Origin', '*')
                .set('Content-Type', 'application/json')
                .set('Accept', 'application/json')
                .set('Access-Control-Allow-Headers', '*')
                .set('Authorization', 'Bearer ' + localStorage.getItem('acces_token'))
        };
        return httpOptionsWithAuthenticationHeaders;
    }
}