<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h2>{{ 'omgeving-1' | translate }}</h2>
            <p>
                {{ 'omgeving-2' | translate }}
            </p>
            <p>
                {{ 'omgeving-3' | translate }}
            </p>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-sm-12">
            <h4>{{ 'omgeving-4' | translate }}</h4>
            <h5>
                <div class="m-4">
                    <div class="accordion" id="myAccordion">
                        <!-- Item 1 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse1">1. {{ 'omgeving-5' | translate }}</button>
                            </h2>
                            <div id="collapse1" class="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-6' | translate }}
                                    </p>
                                    <a href="https://www.demekkerstee.nl/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-5' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 2 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse2">2. {{ 'omgeving-7' | translate }}</button>
                            </h2>
                            <div id="collapse2" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-8' | translate }}
                                    </p>
                                    <a href="https://www.rtm-ouddorp.nl/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-7' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 3 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse3">3. {{ 'omgeving-9' | translate }}</button>
                            </h2>
                            <div id="collapse3" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-10' | translate }}
                                    </p>
                                    <a href="https://www.speeltuindeflipjes.nl/" target="_blank"
                                        class="external-link"><i class="fas fa-external-link-alt"></i>&nbsp;Website {{
                                        'omgeving-9' | translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 4 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse4">4. {{ 'omgeving-11' | translate }}</button>
                            </h2>
                            <div id="collapse4" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-12' | translate }}
                                    </p>
                                    <a href="https://forten.nl/activiteit/bunkerroute-de-punt/" target="_blank"
                                        class="external-link"><i class="fas fa-external-link-alt"></i>&nbsp;Website {{
                                        'omgeving-13' | translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 5 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse5">5. {{ 'omgeving-14' | translate }}</button>
                            </h2>
                            <div id="collapse5" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-15' | translate }}
                                    </p>
                                    <a href="https://surfschoolouddorp.nl/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-14' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 6 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse6">6. {{ 'omgeving-16' | translate }}</button>
                            </h2>
                            <div id="collapse6" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-17' | translate }}
                                    </p>
                                    <a href="https://www.natural-high.nl/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-16' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 7 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse7">7. {{ 'omgeving-18' | translate }}</button>
                            </h2>
                            <div id="collapse7" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-19' | translate }}
                                    </p>
                                    <a href="https://www.debever.com/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-18' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 8 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse8">8. {{ 'omgeving-20' | translate }}</button>
                            </h2>
                            <div id="collapse8" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-21' | translate }}
                                    </p>
                                    <a href="https://www.brouwersdam.nl/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-20' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 9 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse9">9. {{ 'omgeving-22' | translate }}</button>
                            </h2>
                            <div id="collapse9" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-23' | translate }}
                                    </p>
                                    <a href="https://www.centerparcs.nl/nl-nl/nederland/fp_PZ_vakantiepark-port-zelande/alle-activiteiten"
                                        target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-24' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 10 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse10">10. {{ 'omgeving-25' | translate }}</button>
                            </h2>
                            <div id="collapse10" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-26' | translate }}
                                    </p>
                                    <a href="https://www.dolphin-ribs.com/nl/rib-varen/rib-varen-zeeland"
                                        target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-25' |
                                        translate }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Item 11 -->
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#collapse11">11. {{ 'omgeving-27' | translate }}</button>
                            </h2>
                            <div id="collapse11" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                <div class="card-body">
                                    <p class="card-body-text">
                                        {{ 'omgeving-28' | translate }}
                                    </p>
                                    <a href="https://www.aseal.nl/" target="_blank" class="external-link"><i
                                            class="fas fa-external-link-alt"></i>&nbsp;Website {{ 'omgeving-27' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </h5>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-sm-12">
            <h4>{{ 'omgeving-29' | translate }}</h4>
            <div class="m-4">
                <div class="accordion" id="myAccordion">
                    <p>
                        {{ 'omgeving-30' | translate }}
                    </p>
                    <ul>
                        <li><a href="https://eetcafewestduin.nl/" target="_blank" class="external-link">Eetcafé Westduin</a></li>
                        <li><a href="https://www.klepperstee.nl/bistro" target="_blank" class="external-link">Bistro Skål</a></li>
                        <li><a href="https://groenelanteern.nl/" target="_blank" class="external-link">Restaurant In D' Groene Lanteern</a></li>
                        <li><a href="https://www.facebook.com/indekeulsepot/" target="_blank" class="external-link">In De Keulse Pot</a></li>
                        <li><a href="https://www.molenzicht-ouddorp.nl/" target="_blank" class="external-link">Molenzicht</a></li>
                        <li><a href="https://grillandmore.nl/" target="_blank" class="external-link">Grill and More</a></li>
                        <li><a href="https://www.hotelakershoek.nl/" target="_blank" class="external-link">Hotel Cafe Restaurant Akershoek</a></li>
                        <li><a href="https://www.shalom-ouddorp.nl/" target="_blank" class="external-link">Shalom</a></li>
                    </ul>
                    <p>
                        {{ 'omgeving-31' | translate }}
                    </p>
                    <ul>
                        <li><a href="https://www.strandclubzee.nl/" target="_blank" class="external-link">Strandclub Zee</a></li>
                        <li><a href="https://www.tgorsje.nl/" target="_blank" class="external-link">Beachclub 't Gorsje</a></li>
                        <li><a href="https://www.strandpaviljoenpaal10.nl/nl/home" target="_blank" class="external-link">Strandpaviljoen Paal 10</a></li>
                        <li><a href="https://www.natural-high.nl/" target="_blank" class="external-link">Beachclub Natural High</a></li>
                        <li><a href="https://www.bijmarc.nl/nl/home" target="_blank" class="external-link">Bij Marc</a></li>
                        <li><a href="https://dezeesterouddorp.nl/" target="_blank" class="external-link">Standcafe De Zeester</a></li>
                    </ul>
                    <p>
                        {{ 'omgeving-32' | translate }}
                    </p>
                    <ul>
                        <li><a href="https://cafetariathoekje.nl/" target="_blank" class="external-link">Eetwinkel 'T Hoekje</a></li>
                        <li><a href="http://www.snackbar-ouddorp.nl/" target="_blank" class="external-link">Snackbar Lekkerrr</a></li>
                        <li><a href="https://www.eterijdering.nl/" target="_blank" class="external-link">Eterij De Ring</a></li>
                        <li><a href="https://www.instagram.com/snackbardidi/" target="_blank" class="external-link">Snackbar DIDI</a></li>
                        <li><a href="https://www.visspecialisthameeteman.nl/" target="_blank" class="external-link">Visrestaurant Hameeteman</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>