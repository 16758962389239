<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h2>{{ 'zilt-1' | translate}}</h2>
            <p>
                {{ 'zilt-2' | translate}}
            </p>
        </div>
    </div>
</div>

<div class="container relative extraspacing">
    <div class="colordiv"></div>
    <div class="spacing"></div>
    <div class="row colordiv-height">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Zilt/IMG_2768.JPG">
                </div>
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Zilt/IMG_2777.JPG">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Zilt/IMG_2823.JPG">
                </div>
                <div class="col-md-6 nopadding">
                    <img class="img1" src="../../assets/foto's/Zilt/IMG_2839.JPG">
                </div>
            </div>
            <div class="spacing"></div>
        </div>
        <div class="col-md-6">
            <h3>
                {{ 'zilt-3' | translate}}
            </h3>
            <p>
                {{ 'zilt-4' | translate}}
            </p>
            <a class="btn btn-xl btn-outline-light" routerLink="/prijzen-en-reserveren">
                {{ 'zilt-5' | translate}}
            </a>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h3>
                {{ 'zilt-6' | translate}}
            </h3>
            <p>
                {{ 'zilt-7' | translate}}
            </p>
        </div>
    </div>

    <p></p>

    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'zilt-8' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <p>
                {{ 'zilt-9' | translate}}
            </p>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-3">
            <h4>{{ 'zilt-10' | translate}}</h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <b>
                {{ 'zilt-11' | translate}}
            </b>
            <p>
                {{ 'zilt-12' | translate}}
            </p>
            <b>
                {{ 'zilt-13' | translate}}
            </b>
            <p>
                {{ 'zilt-14' | translate}}
            </p>
            <b>
                {{ 'zilt-15' | translate}}
            </b>
            <p>
                {{ 'zilt-16' | translate}}
            </p>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'zilt-17' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-6">
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-18' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-19' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-20' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-21' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-22' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-23' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-24' | translate}}</p>
                </div>
                <div class="col-md-6">
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-25' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-26' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-27' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-28' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-29' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-30' | translate}}</p>
                    <p><i class="fas fa-check"></i>&nbsp;{{ 'zilt-31' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'zilt-32' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <table class="table table-striped" *ngIf="this.prijzenZilt !== undefined">
                    <thead>
                        <tr>
                            <th scope="col">{{ 'zilt-33' | translate}}</th>
                            <th scope="col">{{ 'zilt-34' | translate}}</th>
                            <th scope="col">{{ 'zilt-35' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prijs of this.prijzenZilt.Prijzen | orderBy:indexValue">
                            <td>
                                <span *ngIf="currentLanguage === 'nl'">{{ prijs.datumNl }}</span>
                                <span *ngIf="currentLanguage === 'en'">{{ prijs.datumEng }}</span>
                                <span *ngIf="currentLanguage === 'de'">{{ prijs.datumDe }}</span>
                            </td>
                            <td>
                                <span *ngIf="prijs.periode === 'Midweek'">
                                    {{ 'zilt-41' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Week'">
                                    {{ 'zilt-42' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Weekend'">
                                    {{ 'zilt-40' | translate}}
                                </span>
                            </td>
                            <td>{{ prijs.prijs }}</td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'zilt-38' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <table class="table table-striped" *ngIf="this.prijzenZiltSpecial !== undefined">
                    <thead>
                        <tr>
                            <th scope="col">{{ 'zilt-33' | translate}}</th>
                            <th scope="col">{{ 'zilt-34' | translate}}</th>
                            <th scope="col">{{ 'zilt-35' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prijs of this.prijzenZiltSpecial.Prijzen | orderBy:indexValue">
                            <td>
                                <span *ngIf="currentLanguage === 'nl'">{{ prijs.datumNl }}</span>
                                <span *ngIf="currentLanguage === 'en'">{{ prijs.datumEng }}</span>
                                <span *ngIf="currentLanguage === 'de'">{{ prijs.datumDe }}</span>
                            </td>
                            <td>
                                <span *ngIf="prijs.periode === 'Midweek'">
                                    {{ 'zilt-41' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Week'">
                                    {{ 'zilt-42' | translate}}
                                </span>
                                <span *ngIf="prijs.periode === 'Weekend'">
                                    {{ 'zilt-40' | translate}}
                                </span>
                            </td>
                            <td>{{ prijs.prijs }}</td>
                        </tr>    
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <h4>
                {{ 'zilt-53' | translate}}
            </h4>
            <div class="border-blue-top"></div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <p>
                    {{ 'zilt-54' | translate}}
                </p>
                <p></p>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{{ 'zilt-55' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ 'zilt-56' | translate}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'zilt-57' | translate}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'zilt-58' | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="stripe"></div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <ng-image-slider [images]="imageObject" [arrowKeyMove]="true" speed="1" [animationSpeed]="0.4" paginationShow="true" infinite="true" #navigation></ng-image-slider>
        </div>
    </div>
</div>

<div class="bannercontainer">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>
                    {{ 'zilt-36' | translate}}
                </h3>
                <p></p>
                <a class="btn btn-xl btn-outline-light" routerLink="/vakantieappartementen">
                    {{ 'zilt-37' | translate}}
                  </a>
            </div>
        </div>
    </div>
</div>