import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { PrijzenList } from '../shared/prijs.model';

@Component({
  selector: 'app-vakantieappartement-zilt',
  templateUrl: './vakantieappartement-zilt.component.html',
  styleUrls: ['./vakantieappartement-zilt.component.scss']
})
export class VakantieappartementZiltComponent {
  public prijzenZilt: PrijzenList;
  public prijzenZiltSpecial: PrijzenList;
  public currentLanguage: string;

  constructor(private _lodgeBackendService: LodgeBackendService, translateService: TranslateService) {
    this.getPrijzenZilt();
    this.currentLanguage = translateService.currentLang;
  }

  public getPrijzenZilt(): void {
    this._lodgeBackendService.getPrijzen('Zilt').subscribe(val => {
      const result = new PrijzenList().deserialize(val);
      this.prijzenZilt = new PrijzenList().deserialize(result.Prijzen.filter(p => !p.isSpecialDate));
      this.prijzenZiltSpecial = new PrijzenList().deserialize(result.Prijzen.filter(p => p.isSpecialDate));
    });
  }

  imageObject: Array<object> = [
    {
      image: '../../assets/foto-carousel/zilt/IMG_2839.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2839.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2844.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2844.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2840.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2840.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2826.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2826.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2824.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2824.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2817.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2817.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2837.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2837.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2835.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2835.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2772.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2772.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2789.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2789.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2783.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2783.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2781.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2781.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2833.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2833.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2832.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2832.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2812.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2812.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2811.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2811.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2809.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2809.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2806.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2806.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_2804.JPG',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_2804.JPG',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_0917.jpg',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_0917.jpg',
    }, {
      image: '../../assets/foto-carousel/zilt/IMG_0915.jpg',
      thumbImage: '../../assets/foto-carousel/zilt/IMG_0915.jpg',
    }
  ];
}
