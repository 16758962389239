import { Deserializable } from "./deserializable";

export class ReservationPostBackend implements Deserializable {
  aankomstdatum: Date;
  vertrekdatum: Date;
  appartement: string;
  naamomschrijving: string;
  email: string;
  telefoonnummer: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}