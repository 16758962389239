import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { KalenderComponent } from '../kalender/kalender.component';
import { LodgeBackendService } from '../services/lodge-backend.service';
import { Prijs, PrijzenList } from '../shared/prijs.model';
import { ReservationPostBackend } from '../shared/reservation-post-backend.model';
import { ReservationWithAuth, ReservationWithAuthList } from '../shared/reservationwithauth.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public dropdownOptions: any =
  [
    'Zilt - 4 personen',
    'Sand - 6 personen'
  ];
  public reservations: ReservationWithAuthList;
  public showHistory: boolean = false;
  @ViewChild(KalenderComponent) kalenderComponent: KalenderComponent;
  public prijzenZilt: PrijzenList;
  public prijzenSand: PrijzenList;
  public prijzenZiltSpecial: PrijzenList;
  public prijzenSandSpecial: PrijzenList;
  
  reserveringToevoegenForm = new FormGroup({
    aankomstdatumControl: new FormControl('', [Validators.required]),
    vertrekdatumControl: new FormControl('', [Validators.required]),
    appartementControl: new FormControl('', [Validators.required]),
    naamControl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    emailControl: new FormControl('', [Validators.maxLength(40)]),
    telefoonnummerControl: new FormControl('', [Validators.maxLength(25)])
  });

  constructor(private _lodgeBackendService: LodgeBackendService, public datepipe: DatePipe) {
    this.reserveringToevoegenForm.get('appartementControl').setValue(this.dropdownOptions[0]);
    this.GetPrijzen();
  }

  ngOnInit(): void {
    this.reservations = this.GetReservations();
  }

  public DeletePrijs(prijzen: PrijzenList, deletePrijsId: string): void {
    const index = prijzen.Prijzen.findIndex(p => p.id === deletePrijsId); 
    prijzen.Prijzen.splice(index, 1);
  }

  public GetPrijzen(): void {
    var resultZilt = this._lodgeBackendService.getPrijzen('Zilt');
    resultZilt.subscribe(val => {
       const result = new PrijzenList().deserialize(val);
       this.prijzenZilt = new PrijzenList().deserialize(result.Prijzen.filter(p => !p.isSpecialDate));
       this.prijzenZiltSpecial = new PrijzenList().deserialize(result.Prijzen.filter(p => p.isSpecialDate));
    })
    var resultSand = this._lodgeBackendService.getPrijzen('Sand');
    resultSand.subscribe(val => {
      const result = new PrijzenList().deserialize(val);
      this.prijzenSand = new PrijzenList().deserialize(result.Prijzen.filter(p => !p.isSpecialDate));
      this.prijzenSandSpecial = new PrijzenList().deserialize(result.Prijzen.filter(p => p.isSpecialDate));
    })
  }

  public VoegRijToe(prijzen: PrijzenList, isSpecial: boolean) {
    let nieuwePrijs = new Prijs();
    nieuwePrijs.datumNl = 'LEEG';
    nieuwePrijs.datumEng = 'LEEG';
    nieuwePrijs.datumDe = 'LEEG';
    nieuwePrijs.periode = 'Week';
    nieuwePrijs.prijs = '€LEEG';
    nieuwePrijs.isSpecialDate = isSpecial;
    prijzen.Prijzen.push(nieuwePrijs);
  }

  public UpdatePrijzen(prijzen: PrijzenList, appartement: string, isSpecialDate: boolean) {
    prijzen.Prijzen.forEach(prijs => {
      prijs.indexValue = prijzen.Prijzen.indexOf(prijs);
    }); 
    var result = this._lodgeBackendService.putPrijzen(prijzen.Prijzen, appartement, isSpecialDate).toPromise();
    result.then(val => {
      this.refreshPage();
    }).catch(error => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success spacingleft-swalalertbutton',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire(
        'Error',
        'Er is iets fout gegaan. De prijzen zijn niet geüpdate. Probeer het opnieuw.',
        'error'
      )
      console.error(error);
    });
  }

  public ShowHistory(any): void {
    this.showHistory = any.checked;
    this.GetReservations();
  }

  public GetReservations(): any {
    var result = this._lodgeBackendService.getAllReservations(this.showHistory);
    result.subscribe(val => {
      this.reservations = new ReservationWithAuthList().deserialize(val);
    });
  }

  public VerwijderReservering(reserveringId: number): void {
    let reservation = this.reservations.Reservations.find(r => r.id === reserveringId);
    if(reservation !== null) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success spacingleft-swalalertbutton',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      
      swalWithBootstrapButtons.fire({
        title: 'Weet je zeker dat je deze reserving wilt verwijderen?',
        text: "Deze actie kan niet ongedaan gemaakt worden.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder de reservering',
        cancelButtonText: 'Nee, annuleren',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.GooiReserveringWegMetId(reservation.id).then(result => {
            swalWithBootstrapButtons.fire(
              'Verwijderd!',
              'De reservering is verwijderd.',
              'success'
            );
            this.refreshPage();
            this.GetReservations();
          }).catch(error => {
            swalWithBootstrapButtons.fire(
              'Error',
              'Er is iets fout gegaan. De reservering is niet verwijderd. Probeer het opnieuw.',
              'error'
            )
            console.error(error);
          });
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Geannuleerd',
            'De reservering is niet verwijderd.',
            'error'
          )
        }
      })
    }
  }
  
  private GooiReserveringWegMetId(reserveringId: number): Promise<ArrayBuffer> {
    return this._lodgeBackendService.deleteReservation(reserveringId).toPromise();
  }

  public WijzigReservering(reserveringId: number): void {
    let reservation = this.reservations.Reservations.find(r => r.id === reserveringId);

    Swal.fire({
      title: 'Reservering aanpassen met ID ' + reserveringId,
      html: `
      <p style="float:left; margin-bottom: 0;">Aankomstdatum:</p>
      <input type="date" id="aankomstdatum" class="swal2-input" placeholder="Aankomstdatum">
      <p style="float:left; margin-bottom: 0;">Vertrekdatum:</p>
      <input type="date" id="vertrekdatum" class="swal2-input" placeholder="Vertrekdatum">
      <p style="float:left; margin-bottom: 0;">Appartement:</p><br />
      <select name="appartement" id="appartement">
        <option value="Zilt">Zilt</option>
        <option value="Sand">Sand</option>
      </select><br />
      <p style="float:left; margin-bottom: 0;">Omschrijving:</p>
      <input type="text" id="omschrijving" class="swal2-input" placeholder="omschrijving">
      <p style="float:left; margin-bottom: 0;">Email:</p>
      <input type="text" id="email" class="swal2-input" placeholder="email">
      <p style="float:left; margin-bottom: 0;">Telefoonnummer:</p>
      <input type="text" id="telefoonnummer" class="swal2-input" placeholder="telefoonnummer">
      `,
      confirmButtonText: 'Aanpassen',
      didOpen: () => {
        var aankomstdatum = Swal.getPopup().querySelector('#aankomstdatum') as HTMLInputElement;
        let aankomstdatumString = new Date(reservation.aankomstdatum);
        aankomstdatumString.setDate(aankomstdatumString.getDate() + 1);
        aankomstdatum.valueAsDate = aankomstdatumString;
        var vertrekdatum = Swal.getPopup().querySelector('#vertrekdatum') as HTMLInputElement;
        let vertrekdatumString = new Date(reservation.vertrekdatum);
        vertrekdatumString.setDate(vertrekdatumString.getDate() + 1);
        vertrekdatum.valueAsDate = vertrekdatumString;
        var omschrijving = Swal.getPopup().querySelector('#omschrijving') as HTMLInputElement;
        omschrijving.value = reservation.naamOmschrijving;
        var email = Swal.getPopup().querySelector('#email') as HTMLInputElement;
        email.value = reservation.email;
        var telefoonnummer = Swal.getPopup().querySelector('#telefoonnummer') as HTMLInputElement;
        telefoonnummer.value = reservation.telefoonnummer;
        var appartement = Swal.getPopup().querySelector('#appartement') as HTMLInputElement;
        appartement.value = reservation.appartement;
      },
      focusConfirm: false,
      preConfirm: () => {
        const aankomstdatum = Swal.getPopup().querySelector('#aankomstdatum') as HTMLInputElement;
        const vertrekdatum = Swal.getPopup().querySelector('#vertrekdatum') as HTMLInputElement;
        const appartement = Swal.getPopup().querySelector('#appartement') as HTMLInputElement;
        const omschrijving = Swal.getPopup().querySelector('#omschrijving') as HTMLInputElement;
        const email = Swal.getPopup().querySelector('#email') as HTMLInputElement;
        const telefoonnummer = Swal.getPopup().querySelector('#telefoonnummer') as HTMLInputElement;
        if (!aankomstdatum.value || !vertrekdatum.value || !appartement.value || !omschrijving.value) {
          Swal.showValidationMessage(`Onjuiste gegevens ingevuld`)
        }
        return { aankomstdatum: aankomstdatum.value, vertrekdatum: vertrekdatum.value, appartement: appartement.value, omschrijving: omschrijving.value, email: email.value, telefoonnummer: telefoonnummer.value }
      }
    }).then((result) => {
      let reservation = new ReservationWithAuth();
      reservation.aankomstdatum = new Date(result.value.aankomstdatum);
      reservation.vertrekdatum = new Date(result.value.vertrekdatum);
      reservation.appartement = result.value.appartement;
      reservation.naamOmschrijving = result.value.omschrijving;
      reservation.email = result.value.email;
      reservation.telefoonnummer = result.value.telefoonnummer;
      reservation.id = reserveringId;
      this._lodgeBackendService.updateReservation(reservation).toPromise().then(result => {
        this.refreshPage();
        this.GetReservations();
        Swal.fire({
          title: 'Info',
          text: 'De reservering is succesvol aangepast.',
          icon: 'info',
          showConfirmButton: true,
          allowOutsideClick: true,
        })
      })
    })
  }

  public VoegReserveringToe(): void {
    if(this.reserveringToevoegenForm.invalid){
      return;
    }

    Swal.fire({
      title: 'Info',
      text: 'De reservering wordt verzonden...',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    let appartement = '';
    if(this.reserveringToevoegenForm.controls['appartementControl'].value === 'Zilt - 4 personen') {
      appartement = 'Zilt';
    }
    else {
      appartement = 'Sand'
    }
    let reservationForm = new ReservationPostBackend();
    let aankomstdatum = new Date(this.reserveringToevoegenForm.controls['aankomstdatumControl'].value);
    let vertrekdatum = new Date(this.reserveringToevoegenForm.controls['vertrekdatumControl'].value);
    reservationForm.aankomstdatum = new Date(Date.UTC(aankomstdatum.getFullYear(), aankomstdatum.getMonth(), aankomstdatum.getDate()));
    reservationForm.vertrekdatum = new Date(Date.UTC(vertrekdatum.getFullYear(), vertrekdatum.getMonth(), vertrekdatum.getDate()));
    reservationForm.appartement = appartement;
    reservationForm.naamomschrijving = this.reserveringToevoegenForm.controls['naamControl'].value;
    reservationForm.email = this.reserveringToevoegenForm.controls['emailControl'].value;
    reservationForm.telefoonnummer = this.reserveringToevoegenForm.controls['telefoonnummerControl'].value;
    
    var result = this._lodgeBackendService.postReservation(reservationForm);
    result.subscribe(val => {
      Swal.close();
      if (val === null || val === undefined) {
        this.throwErrorSwal();
      }
      this.GetReservations();
      this.refreshPage();
      Swal.fire({
        title: 'Info',
        text: 'De reservering is succesvol aangemaakt.',
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: true,
      })
      this.reserveringToevoegenForm.reset();
    }, err => {
      Swal.close();
      this.throwErrorSwal();
    });
  }

  throwErrorSwal() {
    Swal.fire({
      icon: 'error',
      title: 'Oeps...',
      text: 'Er is iets fout gegaan! Probeer het opnieuw...',
      showConfirmButton: true,
      allowOutsideClick: true,
    })
  }

  private refreshPage() {
    window.location.reload();
  }
}
