import { Deserializable } from "./deserializable";

export class Contactform implements Deserializable {
  Naam: string;
  Email: string;
  Bericht: string;
  RecaptchaToken: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}