<nav
    class="header navbar navbar-expand-sm sticky-top navbar-light bg-light navbar navbar-expand-lg navbar-light bg-light header zindex2 sticky-top">
    <img src="../../assets/foto's/logo/logo_large.png" alt="Logo Lodge21 Ouddorp" class="logo-lodge21 bigscreennavbar" routerLink="/" />
    <img src="../../assets/foto's/logo/logo_large.png" alt="Logo Lodge21 Ouddorp" class="logo-lodge21 mobilenavbar" routerLink="/" data-toggle="collapse" data-target="#navbarNav" />
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse zindex20" id="navbarNav">
        <ul class="navbar-nav center-right">
            <li class="nav-item">
                <a class="nav-link bigscreennavbar" routerLink="/">{{'menu-1' | translate}}</a>
                <a class="nav-link mobilenavbar" data-toggle="collapse" data-target="#navbarNav" routerLink="/">{{'menu-1' | translate}}</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown">
                    <a class="nolinkstyling bigscreennavbar" routerLink="/vakantieappartementen">{{'menu-2' | translate}}</a>
                    <a class="nolinkstyling mobilenavbar" routerLink="/vakantieappartementen" data-toggle="collapse" data-target="#navbarNav">{{'menu-2' | translate}}</a>
                </a>
                <div id="dropdown-menu" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item nav-link bigscreennavbar" routerLink="/vakantieappartementen/zilt">{{'menu-3' | translate}}</a>
                    <a class="dropdown-item nav-link bigscreennavbar" routerLink="/vakantieappartementen/sand">{{'menu-4' | translate}}</a>
                    <a class="dropdown-item nav-link mobilenavbar" data-toggle="collapse" data-target="#navbarNav" routerLink="/vakantieappartementen/zilt">{{'menu-3' | translate}}</a>
                    <a class="dropdown-item nav-link mobilenavbar" data-toggle="collapse" data-target="#navbarNav" routerLink="/vakantieappartementen/sand">{{'menu-4' | translate}}</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link bigscreennavbar" routerLink="/omgeving">{{'menu-5' | translate}}</a>
                <a class="nav-link mobilenavbar" data-toggle="collapse" data-target="#navbarNav" routerLink="/omgeving">{{'menu-5' | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link bigscreennavbar" routerLink="/prijzen-en-reserveren">{{'menu-6' | translate}}</a>
                <a class="nav-link mobilenavbar" data-toggle="collapse" data-target="#navbarNav" routerLink="/prijzen-en-reserveren">{{'menu-6' | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link bigscreennavbar" routerLink="/contact">{{'menu-7' | translate}}</a>
                <a class="nav-link mobilenavbar" data-toggle="collapse" data-target="#navbarNav" routerLink="/contact">{{'menu-7' | translate}}</a>
            </li>
            <li class="nav-item">
                <img src="../../assets/foto's/Vlaggen/VlagNL.png" alt="Vlag Nederland" (click)="ChangeLanguageNederlands()" class="flag" />
                <img src="../../assets/foto's/Vlaggen/VlagENG.jpg" alt="Vlag Engeland" (click)="ChangeLanguageEngels()" class="flag" />
                <img src="../../assets/foto's/Vlaggen/VlagDUI.jpg" alt="Vlag Engeland" (click)="ChangeLanguageDuits()" class="flag" />
            </li>
        </ul>
    </div>
</nav>