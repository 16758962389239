<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <h3>Adminportaal</h3>
            <p>Welkom op het admin portaal. Hier kunnen instellingen voor de website beheert worden.</p>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12">
            <app-kalender></app-kalender>
        </div>
    </div>
</div>

<div class="container extraspacing">
    <div class="row">
        <div class="col-md-12 shadowbox">
            <form [formGroup]="reserveringToevoegenForm" autocomplete="off" class="reservationform">
                <h5>Reservering toevoegen</h5>
                <p></p>
                <mat-form-field class="fullwidth">
                    <mat-label>Aankomstdatum *</mat-label>
                    <input matInput [matDatepicker]="aankomstdatum" formControlName="aankomstdatumControl">
                    <mat-datepicker-toggle matSuffix [for]="aankomstdatum"></mat-datepicker-toggle>
                    <mat-datepicker #aankomstdatum></mat-datepicker>
                    <mat-error *ngIf="reserveringToevoegenForm.get('aankomstdatumControl').hasError('required')">
                        Aankomstdatum is verplicht
                    </mat-error>
                </mat-form-field>
                &nbsp;
                <mat-form-field class="fullwidth">
                    <mat-label>Vertrekdatum *</mat-label>
                    <input matInput [matDatepicker]="vertrekdatum" formControlName="vertrekdatumControl">
                    <mat-datepicker-toggle matSuffix [for]="vertrekdatum"></mat-datepicker-toggle>
                    <mat-datepicker #vertrekdatum></mat-datepicker>
                    <mat-error *ngIf="reserveringToevoegenForm.get('vertrekdatumControl').hasError('required')">
                        Vertrekdatum is verplicht
                    </mat-error>
                </mat-form-field>
                <p></p>

                <mat-form-field appearance="fill" class="namefill">
                    <mat-label>Naam van gast *</mat-label>
                    <input type="naam" matInput formControlName="naamControl">
                    <mat-error *ngIf="reserveringToevoegenForm.get('naamControl').hasError('required')">
                        Naam is <strong>verplicht</strong>
                    </mat-error>
                    <mat-error *ngIf="reserveringToevoegenForm.get('naamControl').hasError('minlength')">
                        Naam is te kort
                    </mat-error>
                    <mat-error *ngIf="reserveringToevoegenForm.get('naamControl').hasError('maxlength')">
                        Naam is te lang
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="namefill">
                    <mat-label>Emailadres van gast</mat-label>
                    <input type="naam" matInput formControlName="emailControl">
                    <mat-error *ngIf="reserveringToevoegenForm.get('emailControl').hasError('maxlength')">
                        Emailadres is te lang
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="namefill">
                    <mat-label>Telefoonnummer van gast</mat-label>
                    <input type="naam" matInput formControlName="telefoonnummerControl">
                    <mat-error *ngIf="reserveringToevoegenForm.get('telefoonnummerControl').hasError('maxlength')">
                        Telefoonnummer is te lang
                    </mat-error>
                </mat-form-field>

                &nbsp;

                <mat-select placeholder="Kies appartement" name="option" formControlName="appartementControl">
                    <mat-option *ngFor="let option of dropdownOptions" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>

                <p></p>

                <a class="btn btn-xl btn-outline-light child-on-bottom" (click)="VoegReserveringToe()">
                    Toevoegen
                </a>
            </form>
        </div>
    </div>
</div>

<div class="container extraspacing" *ngIf="reservations !== undefined">
    <div class="row">
        <div class="col-md-12 shadowbox">
            <h5>Alle reserveringen</h5>
            <p></p>
            <mat-checkbox (change)="ShowHistory($event)">
                Geschiedenis tonen
            </mat-checkbox>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Aankomstdatum</th>
                        <th scope="col">Vertrekdatum</th>
                        <th scope="col">Omschrijving</th>
                        <th scope="col">Appartement</th>
                        <th scope="col">Email</th>
                        <th scope="col">Telefoonnummer</th>
                        <th scope="col">Actie</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let reservation of reservations.Reservations">
                        <th scope="row">{{ reservation.id }}</th>
                        <td>{{ reservation.aankomstdatum | date: 'dd-MM-yyyy' }}</td>
                        <td>{{ reservation.vertrekdatum | date: 'dd-MM-yyyy' }}</td>
                        <td>{{ reservation.naamOmschrijving }}</td>
                        <td>{{ reservation.appartement }}</td>
                        <td>{{ reservation.email != null && reservation.email != '' ? reservation.email : '-' }}</td>
                        <td>{{ reservation.telefoonnummer != null && reservation.telefoonnummer != '' ?
                            reservation.telefoonnummer : '-' }}</td>
                        <td>
                            <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                                (click)="WijzigReservering(reservation.id)">
                                Wijzigen
                            </a>
                            &nbsp;
                            <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                                (click)="VerwijderReservering(reservation.id)">
                                Verwijderen
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="container extraspacing" *ngIf="this.prijzenZilt !== undefined && this.prijzenSand !== undefined">
    <div class="row">
        <div class="col-md-12 shadowbox">
            <h5>Prijzen regulier</h5>
            <p></p>
            <mat-tab-group>
                <!-- Tab 1 -->
                <mat-tab [label]="'Prijzen Zilt'">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Datum (NL)</th>
                                <th scope="col">Datum (ENG)</th>
                                <th scope="col">Datum (DE)</th>
                                <th scope="col">Periode</th>
                                <th scope="col">Prijs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prijs of prijzenZilt.Prijzen | orderBy:indexValue; let index = index;">
                                <th scope="row"><a class="clickable" (click)="DeletePrijs(prijzenZilt, prijs.id)"><i class="fa fa-trash"></i></a></th>
                                <td><input type="text" [(ngModel)]="prijs.datumNl" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumEng" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumDe" /></td>
                                <td>
                                    <select name="periode" [(ngModel)]="prijs.periode">
                                        <option value="Week">Week</option>
                                        <option value="Weekend">Weekend</option>
                                        <option value="Midweek">Midweek</option>
                                    </select>
                                </td>
                                <td><input type="text" [(ngModel)]="prijs.prijs" /></td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="VoegRijToe(prijzenZilt, false)">
                        Rij toevoegen
                    </a>
                    &nbsp;
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="UpdatePrijzen(prijzenZilt, 'Zilt', false)">
                        Wijzigen doorvoeren
                    </a>
                </mat-tab>
                <!-- Tab 2 -->
                <mat-tab [label]="'Prijzen Sand'">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Datum (NL)</th>
                                <th scope="col">Datum (ENG)</th>
                                <th scope="col">Datum (DE)</th>
                                <th scope="col">Periode</th>
                                <th scope="col">Prijs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prijs of prijzenSand.Prijzen | orderBy:indexValue; let index = index;">
                                <th scope="row"><a class="clickable" (click)="DeletePrijs(prijzenSand, prijs.id)"><i class="fa fa-trash"></i></a></th>
                                <td><input type="text" [(ngModel)]="prijs.datumNl" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumEng" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumDe" /></td>
                                <td>
                                    <select name="periode" [(ngModel)]="prijs.periode">
                                        <option value="Week">Week</option>
                                        <option value="Weekend">Weekend</option>
                                        <option value="Midweek">Midweek</option>
                                    </select>
                                </td>
                                <td><input type="text" [(ngModel)]="prijs.prijs" /></td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="VoegRijToe(prijzenSand, false)">
                        Rij toevoegen
                    </a>
                    &nbsp;
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="UpdatePrijzen(prijzenSand, 'Sand', false)">
                        Wijzigen doorvoeren
                    </a>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<div class="container extraspacing" *ngIf="this.prijzenZiltSpecial !== undefined && this.prijzenSandSpecial !== undefined">
    <div class="row">
        <div class="col-md-12 shadowbox">
            <h5>Prijzen speciale dagen</h5>
            <p></p>
            <mat-tab-group>
                <!-- Tab 1 -->
                <mat-tab [label]="'Prijzen Zilt'">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Datum (NL)</th>
                                <th scope="col">Datum (ENG)</th>
                                <th scope="col">Datum (DE)</th>
                                <th scope="col">Periode</th>
                                <th scope="col">Prijs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prijs of prijzenZiltSpecial.Prijzen | orderBy:indexValue; let index = index;">
                                <th scope="row"><a class="clickable" (click)="DeletePrijs(prijzenZiltSpecial, prijs.id)"><i class="fa fa-trash"></i></a></th>
                                <td><input type="text" [(ngModel)]="prijs.datumNl" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumEng" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumDe" /></td>
                                <td>
                                    <select name="periode" [(ngModel)]="prijs.periode">
                                        <option value="Week">Week</option>
                                        <option value="Weekend">Weekend</option>
                                        <option value="Midweek">Midweek</option>
                                    </select>
                                </td>
                                <td><input type="text" [(ngModel)]="prijs.prijs" /></td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="VoegRijToe(prijzenZiltSpecial, true)">
                        Rij toevoegen
                    </a>
                    &nbsp;
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="UpdatePrijzen(prijzenZiltSpecial, 'Zilt', true)">
                        Wijzigen doorvoeren
                    </a>
                </mat-tab>
                <!-- Tab 2 -->
                <mat-tab [label]="'Prijzen Sand'">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Datum (NL)</th>
                                <th scope="col">Datum (ENG)</th>
                                <th scope="col">Datum (DE)</th>
                                <th scope="col">Periode</th>
                                <th scope="col">Prijs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prijs of prijzenSandSpecial.Prijzen | orderBy:indexValue; let index = index;">
                                <th scope="row"><a class="clickable" (click)="DeletePrijs(prijzenSandSpecial, prijs.id)"><i class="fa fa-trash"></i></a></th>
                                <td><input type="text" [(ngModel)]="prijs.datumNl" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumEng" /></td>
                                <td><input type="text" [(ngModel)]="prijs.datumDe" /></td>
                                <td>
                                    <select name="periode" [(ngModel)]="prijs.periode">
                                        <option value="Week">Week</option>
                                        <option value="Weekend">Weekend</option>
                                        <option value="Weekend">Midweek</option>
                                    </select>
                                </td>
                                <td><input type="text" [(ngModel)]="prijs.prijs" /></td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="VoegRijToe(prijzenSandSpecial, true)">
                        Rij toevoegen
                    </a>
                    &nbsp;
                    <a class="btn btn-xl btn-outline-light child-on-bottom buttonspacing"
                        (click)="UpdatePrijzen(prijzenSandSpecial, 'Sand', true)">
                        Wijzigen doorvoeren
                    </a>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>