import { Deserializable } from "./deserializable";

export class Reservationform implements Deserializable {
  Voornaam: string;
  Achternaam: string;
  Emailadres: string;
  Telefoonnummer: string;
  Aankomstdatum: string;
  Vertrekdatum: string;
  AantalVolwassenen: number;
  AantalKinderen: number;
  Appartement: string;
  Opmerkingen: string;
  RecaptchaToken: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}